
import { Application, ApplicationFileItem } from "@/models/Application";
import { defineComponent, PropType } from "vue";

export default defineComponent({
    filters: {
        fileSize: (value: number): string => {
            const kb = 1024;
            const mb = kb ** 2; // 1024 * 1024
            const gb = kb ** 3; // 1024 * 1024 * 1024

            if (value < kb) {
                return `${value}B`;
            } else if (value < mb) {
                const kbv = value / kb;

                return `${kbv.toFixed(2)}KB`;
            } else if (value < gb) {
                const mbv = value / mb;

                return `${mbv.toFixed(2)}MB`;
            }

            const gbv = value / gb;

            return `${gbv.toFixed(2)}GB`;
        },
    },
    props: {
        application: {
            type: Object as PropType<Application>,
            required: true,
        },
    },
    data() {
        return {
            fileTypes: [
                "technical file",
                "research protocol",
                "declaration of conformity",
                "clinical safety case",
                "instructions for use",
                "model card",
                "datasheet for datasets",
                "an image representing the application",
                "an argo workflow spec file",
            ],
        };
    },
    computed: {
        standardFiles(): ApplicationFileItem[] {
            const files: ApplicationFileItem[] = [];
            if (this.application.application_version_files?.files) {
                this.application.application_version_files.files.map((file: ApplicationFileItem) => {
                    if (this.fileTypes.includes(file.type.toLocaleLowerCase())) {
                        files.push(file);
                    }
                });
            }
            return files;
        },
        customFiles(): ApplicationFileItem[] {
            const files: ApplicationFileItem[] = [];
            if (this.application.application_version_files?.files) {
                this.application.application_version_files.files.map((file: ApplicationFileItem) => {
                    if (!this.fileTypes.includes(file.type.toLocaleLowerCase())) {
                        files.push(file);
                    }
                });
            }
            return files;
        },
    },
    methods: {
        checkValidFileType(label: string) {
            if (this.fileTypes.some((fileType) => fileType.includes(label))) {
                return true;
            } else {
                return false;
            }
        },
    },
});

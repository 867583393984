
import { defineComponent } from "vue";
import { getCurrentSession, loginViaCognito } from "@/utilities/amplify-auth";
import store from "@/store/index";
import { getDeveloperProfile } from "@/api/DeveloperService";
import { DeveloperProfileStatus } from "@/models/Developer";

export default defineComponent({
    async mounted() {
        let returnPath = sessionStorage.getItem("currentPage") ?? "/";

        try {
            const session = await getCurrentSession();
            store.commit("setAuthenticated", true);
            store.commit("setSession", session);

            if (store.state.roles.includes("developer")) {
                try {
                    const profile = await getDeveloperProfile(DeveloperProfileStatus.LIVE);
                    store.commit("setDeveloperProfile", profile);

                    if (!profile) {
                        returnPath = "/developer-profile";
                    }
                } catch {
                    returnPath = "/developer-profile";
                }
            }

            this.$router.replace({ path: returnPath, replace: true });
        } catch {
            loginViaCognito();
        }
    },
});

import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import { Dictionary, NavigationGuardNext, PositionResult, Route } from "vue-router/types/router";
import store from "@/store/index";
import { AdminRoute, ApplicationRoute, PublishRoute } from "./route-names";
import Dashboard from "@/views/Dashboard.vue";
import Publish from "@/views/Publish.vue";
import Unauthorized from "@/views/Unauthorized.vue";
import ApplicationDetails from "@/views/publish/ApplicationDetails.vue";
import DeploymentRequirements from "@/views/publish/DeploymentRequirements.vue";
import ApplicationFiles from "@/views/publish/ApplicationFiles.vue";
import ContainerUpload from "@/views/publish/ContainerUpload.vue";
import ArgoWorkflowPage from "@/views/publish/ArgoWorkflowPage.vue";
import ApplicationSummary from "@/views/publish/ApplicationSummary.vue";
import InstitutionManagement from "@/views/admin/InstitutionManagement.vue";
import ApplicationWorklist from "@/views/admin/ApplicationWorklist.vue";
import ApplicationReview from "@/views/admin/ApplicationReview.vue";
import PolicyPage from "@/views/PolicyPage.vue";
import DeveloperProfile from "@/views/DeveloperProfile.vue";
import { loginViaCognito, getCurrentSession } from "@/utilities/amplify-auth";
import { CognitoAccessToken } from "amazon-cognito-identity-js";
import LoginCallback from "@/views/LoginCallback.vue";
import DeveloperProfileReview from "@/views/admin/DeveloperProfileReview.vue";

Vue.use(VueRouter);

const applicationRouteGuard = (to: Route, _: Route, next: NavigationGuardNext) => {
    const { application_id, version_id, version_details_id } = to.query as Dictionary<string>;

    !application_id || !version_id || !version_details_id ? next({ name: ApplicationRoute.dashboard }) : next();
};

export const routes: Array<RouteConfig> = [
    {
        path: "/institutions-management",
        name: AdminRoute.institutionManagement,
        component: InstitutionManagement,
        meta: {
            requiredRoles: ["admin"],
            appHeaderTitle: "Manage Institutions",
        },
        beforeEnter: roleAuthenticatedRoute,
    },
    {
        path: "/admin/app-worklist",
        name: AdminRoute.applicationWorklist,
        component: ApplicationWorklist,
        meta: {
            requiredRoles: ["admin"],
            appHeaderTitle: "Application Worklist",
        },
        beforeEnter: roleAuthenticatedRoute,
    },
    {
        path: "/admin/app-review",
        name: AdminRoute.applicationReview,
        component: ApplicationReview,
        meta: {
            requiredRoles: ["admin"],
            appHeaderTitle: "Application Review",
        },
        beforeEnter: roleAuthenticatedRoute,
    },
    {
        path: "/policy",
        name: ApplicationRoute.policyPage,
        component: PolicyPage,
        meta: {
            appHeaderTitle: "Policy Page",
        },
    },
    {
        path: "/admin/developer-review",
        name: AdminRoute.developerReview,
        component: DeveloperProfileReview,
        meta: {
            requiredRoles: ["admin"],
            appHeaderTitle: "Developer Profile Review",
        },
        beforeEnter: roleAuthenticatedRoute,
    },
    {
        path: "/developer-profile",
        name: ApplicationRoute.developerProfile,
        component: DeveloperProfile,
        meta: {
            requiredRoles: ["developer"],
            appHeaderTitle: "Developer Profile",
        },
        beforeEnter: roleAuthenticatedRoute,
    },
    {
        path: "/",
        redirect: "/applications",
    },
    {
        path: "/applications",
        name: ApplicationRoute.dashboard,
        component: Dashboard,
        meta: {
            requiredRoles: ["developer", "admin"],
            requiresAuthentication: true,
            appHeaderTitle: "Application Dashboard",
        },
        beforeEnter: roleAuthenticatedRoute,
    },
    {
        path: "/callback",
        name: "code",
        component: LoginCallback,
        meta: {
            requiresAuthentication: false,
        },
    },
    {
        path: "/applications",
        name: ApplicationRoute.publish,
        component: Publish,
        meta: {
            requiredRoles: ["developer"],
            requiresAuthentication: true,
            appHeaderTitle: "Application Publishing",
        },
        beforeEnter: roleAuthenticatedRoute,
        children: [
            {
                path: "application-details",
                name: PublishRoute.details,
                component: ApplicationDetails,
                meta: {
                    requiresApplication: true,
                    requiredRoles: ["developer"],
                    requiresAuthentication: true,
                    step: 1,
                    nextRoute: PublishRoute.requirements,
                    previousRoute: ApplicationRoute.dashboard,
                },
                beforeEnter: roleAuthenticatedRoute,
            },
            {
                path: "deployment-requirements",
                name: PublishRoute.requirements,
                component: DeploymentRequirements,
                meta: {
                    requiredRoles: ["developer"],
                    requiresAuthentication: true,
                    step: 2,
                    previousRoute: PublishRoute.details,
                    nextRoute: PublishRoute.files,
                },
                beforeEnter: roleAuthenticatedRoute,
            },
            {
                path: "application-files",
                name: PublishRoute.files,
                component: ApplicationFiles,
                meta: {
                    requiredRoles: ["developer"],
                    requiresAuthentication: true,
                    step: 3,
                    previousRoute: PublishRoute.requirements,
                    nextRoute: PublishRoute.containerUpload,
                },
                beforeEnter: roleAuthenticatedRoute,
            },
            {
                path: "container-upload",
                name: PublishRoute.containerUpload,
                component: ContainerUpload,
                meta: {
                    requiredRoles: ["developer"],
                    requiresAuthentication: true,
                    step: 4,
                    previousRoute: PublishRoute.files,
                    nextRoute: PublishRoute.argoWorkflow,
                },
                beforeEnter: roleAuthenticatedRoute,
            },
            {
                path: "argo-workflow",
                name: PublishRoute.argoWorkflow,
                component: ArgoWorkflowPage,
                meta: {
                    requiresApplication: true,
                    requiredRoles: ["developer"],
                    requiresAuthentication: true,
                    step: 5,
                    previousRoute: PublishRoute.containerUpload,
                    nextRoute: PublishRoute.summary,
                },
                beforeEnter: roleAuthenticatedRoute,
            },
            {
                path: "application-summary",
                name: "ApplicationSummary",
                component: ApplicationSummary,
                meta: {
                    requiresApplication: true,
                    requiredRoles: ["developer", "admin"],
                    requiresAuthentication: true,
                    step: 6,
                    previousRoute: PublishRoute.argoWorkflow,
                    nextRoute: ApplicationRoute.dashboard,
                    appHeaderTitle: "Application Publishing",
                },
                beforeEnter: roleAuthenticatedRoute,
            },
        ],
    },
    {
        path: "/logout",
        redirect: "/applications",
    },
    {
        path: "/unauthorized",
        name: ApplicationRoute.unauthorised,
        component: Unauthorized,
    },
];

const propagatedRoutes: Array<RouteConfig> = routes.map((route) => {
    if (!route.children) {
        return route;
    }

    return {
        ...route,
        children: route.children.map((childRoute) => ({
            ...childRoute,
            meta: {
                ...route.meta,
                ...childRoute.meta,
            },
        })),
    };
});

function checkForRequiredRole(
    accessToken: CognitoAccessToken,
    requiredRoles: string[],
    next: NavigationGuardNext<Vue>
) {
    const user = accessToken.decodePayload();
    const userGroups = user["cognito:groups"] ?? [];

    const userHasAllowedGroup = requiredRoles.some((group: string) => userGroups.includes(group));

    return !userHasAllowedGroup ? next({ name: ApplicationRoute.unauthorised }) : next();
}

function roleAuthenticatedRoute(to: Route, _: Route, next: NavigationGuardNext<Vue>) {
    const { requiredRoles } = to.meta || {};

    if (process.env.VUE_APP_AUTH_ENABLED !== "true" || !requiredRoles) {
        return next();
    }

    const accessToken = store.state.session?.getAccessToken();

    if (accessToken) {
        return checkForRequiredRole(accessToken, requiredRoles, next);
    }

    getCurrentSession().then((session) => {
        store.commit("setAuthenticated", true);
        store.commit("setSession", session);

        const accessToken = session.getAccessToken();
        return checkForRequiredRole(accessToken, requiredRoles, next);
    }, loginViaCognito);

    if (to.path.includes("/applications")) {
        applicationRouteGuard(to, _, next);
    }
}

const router = new VueRouter({
    mode: "history",
    routes: propagatedRoutes,
    scrollBehavior(): PositionResult {
        return { x: 0, y: 0 };
    },
});

router.beforeResolve((to, from, next) => {
    window.onpopstate = () => {
        //default functionality on browser back/forward buttons
    };

    if (!to.meta?.requiresAuthentication) {
        return next();
    }

    if (process.env.VUE_APP_AUTH_ENABLED === "false") {
        store.commit("isAuthenticated", true);
    }

    if (store.state.roles.includes("developer") && !store.state.developerProfile) {
        return next({ path: "/developer-profile", replace: true });
    }

    if (from.path.includes("/applications/")) {
        window.onpopstate = () => {
            const answer = window.confirm("The data you have entered on this page will be lost.");
            if (answer) {
                return next();
            } else {
                return next(false);
            }
        };
    }

    next();
});

router.afterEach((to) => {
    if (
        window.location.pathname === "/" ||
        !to.name ||
        to.name === "Unauthorized" ||
        window.location.pathname === "/callback"
    ) {
        return;
    }

    window.sessionStorage.setItem("currentPage", to.fullPath);
});

export default router;

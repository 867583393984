
import ApplicationCard from "../ApplicationCard.vue";
import DetailView from "./DetailView/DetailView.vue";
import { defineComponent } from "vue";

export default defineComponent({
    components: {
        ApplicationCard,
        DetailView,
    },
    props: {
        heroImage: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            applicationPreviewModal: false,
        };
    },
    methods: {
        closeModal() {
            this.applicationPreviewModal = false;
        },
        openModal() {
            this.applicationPreviewModal = true;
        },
    },
});

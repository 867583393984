
import { PropType } from "vue";
import { defineComponent } from "vue";
import { createNewApplicationVersion } from "@/api/ApplicationSummariesService";
import { ApplicationSummary } from "@/models/ApplicationSummary";

export default defineComponent({
    props: {
        application: {
            type: Object as PropType<ApplicationSummary>,
            required: true,
        },
    },
    computed: {},
    data() {
        return {
            loading: false,
            versionString: "",
            isFormValid: false,
            rules: [
                (value: string) => (!!value && value != "") || "Required.",
                (value: string) => (value && value.length >= 1) || "Min 1 characters",
                (value: string) =>
                    this.application.versions.every((version) => version.version_string !== value) ||
                    "Version string already used",
                (value: string) =>
                    /^[a-zA-Z0-9](.*[a-zA-Z0-9])?$/.test(value) || "Cannot start or end with a special character",
            ],
        };
    },
    emits: ["cancel"],
    methods: {
        cancel() {
            this.versionString = "";
            this.$emit("cancel");
        },
        async createNewVersion(e: Event) {
            if (!this.isFormValid) {
                return false;
            }
            const app = await createNewApplicationVersion(this.application.application_id, this.versionString);
            if (app && app.application_id) {
                this.$router.push({
                    name: "ApplicationDetails",
                    query: {
                        application_id: app.application_id,
                        version_id: app.version_id,
                        version_details_id: app.version_details_id,
                    },
                });
            }
        },
    },
});


import { defineComponent } from "vue";
import { formatDate } from "@/utilities/date-utilities";
import CookiePolicy from "@/components/policies/CookiePolicy.vue";
import AccessibilityStatement from "@/components/policies/AccessibilityStatement.vue";
import PrivacyPolicy from "@/components/policies/PrivacyPolicy.vue";

type TabData = {
    currentTab: string;
    tabs: { idx: string; name: string; key: string }[];
};

export default defineComponent({
    metaInfo: {
        title: "Policies",
    },
    components: {
        CookiePolicy,
        PrivacyPolicy,
        AccessibilityStatement,
    },
    filters: {
        formatDate,
    },
    data(): TabData {
        return {
            currentTab: "1",
            tabs: [
                { idx: "0", name: "Cookie Policy", key: "cookie" },
                { idx: "1", name: "Privacy Policy", key: "privacy" },
                { idx: "2", name: "Accessability Statement", key: "accessability" },
            ],
        };
    },
});


import ApplicationSummaryProperty from "./ApplicationSummaryProperty.vue";
import EditApplicationSectionButton from "./EditApplicationSectionButton.vue";
import { formatArgoWorkflowName } from "@/utilities/argo-workflow-helper";
import { defineComponent } from "vue";
import InformationContainer from "@/components/shared/InformationContainer.vue";
import { getPresignedGetUrl } from "@/api/FileUploadService";

export default defineComponent({
    components: {
        EditApplicationSectionButton,
        InformationContainer,
        ApplicationSummaryProperty,
    },
    props: {
        editable: {
            type: Boolean,
            default: true,
        },
        workflowName: {
            type: String,
            default: "",
        },
        previousWorkflowName: {
            type: String,
        },
        hasValueChanged: {
            type: Function,
            default: () => false,
        },
    },
    computed: {
        currentWorkflow(): string {
            return formatArgoWorkflowName(this.workflowName);
        },
        previousWorkflow(): string | undefined {
            if (this.previousWorkflowName !== undefined) {
                return formatArgoWorkflowName(this.previousWorkflowName);
            }
            return undefined;
        },
        sectionEditable(): boolean {
            return this.editable && this.$store.state.currentApplication.status !== "submitted";
        },
    },
    data() {
        return {
            requiredFieldsFilled: false,
        };
    },
    created() {
        this.checkIfRequiredFieldsFilled();
    },
    methods: {
        checkIfRequiredFieldsFilled() {
            if (!this.workflowName) {
                this.requiredFieldsFilled = false;
                this.$store.commit("setCurrentApplicationRequiredFieldsFilled", false);
            } else {
                this.requiredFieldsFilled = true;
            }
        },
        formatArgoWorkflowName,
        async downloadFile(key: string) {
            if (!key) {
                return;
            }

            const url = await getPresignedGetUrl(key);

            window.open(url);
        },
    },
});

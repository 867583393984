
import ContainerFindingsModal from "./ContainerFindingsModal.vue";
import { ContainerScan, ScanProgress, ScanResults } from "@/models/Container";
import InformationContainer, { InformationContainerType } from "@/components/shared/InformationContainer.vue";
import { defineComponent, PropType } from "vue";
import { DataTableHeader } from "vuetify";

type ContainerScansTableData = {
    findingsModalDisplay: boolean;
    containerToView: ScanResults;
    infoType: InformationContainerType;
    text: string;
    title: string;
    displayModal: boolean;
};

const headers: DataTableHeader[] = [
    {
        text: "Repository",
        value: "repositoryName",
        sortable: false,
        align: "start",
    },
    {
        text: "Critical",
        value: "CRITICAL",
        sortable: false,
        align: "center",
    },
    {
        text: "High",
        value: "HIGH",
        sortable: false,
        align: "center",
    },
    {
        text: "Medium",
        value: "MEDIUM",
        sortable: false,
        align: "center",
    },
    {
        text: "Low",
        value: "LOW",
        sortable: false,
        align: "center",
    },
    {
        text: "Informational",
        value: "INFORMATIONAL",
        sortable: false,
        align: "center",
    },
    {
        text: "Undefined",
        value: "UNDEFINED",
        sortable: false,
        align: "center",
    },
    {
        text: "Findings",
        value: "findings",
        sortable: false,
        align: "start",
    },
];

export default defineComponent({
    components: {
        ContainerFindingsModal,
        InformationContainer,
    },
    props: {
        containerScans: {
            type: Object as PropType<ContainerScan>,
            required: true,
        },
    },
    data(): ContainerScansTableData {
        return {
            findingsModalDisplay: false,
            containerToView: {} as ScanResults,
            infoType: InformationContainerType.INFO,
            title: "",
            text: "",
            displayModal: false,
        };
    },
    setup() {
        return {
            headers,
        };
    },
    methods: {
        async getContainerScans() {
            const scanSuccess = this.checkVulnerabilityScanSuccess();

            if (this.containerScans.status === ScanProgress.IN_PROGRESS) {
                this.infoType = InformationContainerType.INFO;
                this.title = "Scan in progress";
                this.text =
                    "Vulnerability scan currently in progress. Identifying whether any security vulnerabilities are present in the application.";
            } else if (this.containerScans.status === ScanProgress.FAILED) {
                this.infoType = InformationContainerType.WARNING;
                this.title = "Warning!";
                this.text = "One or more vulnerability scans failed to run.";
            } else if (this.containerScans.status === ScanProgress.COMPLETE && !scanSuccess) {
                this.infoType = InformationContainerType.WARNING;
                this.title = "Warning! Vulnerabilities detected";
                this.text = "Please review vulnerability scan findings.";
            } else if (this.containerScans.status === ScanProgress.COMPLETE && scanSuccess) {
                this.infoType = InformationContainerType.SUCCESS;
                this.title = "Success! No vulnerabilities detected";
                this.text =
                    "Scan complete. No vulnerabilities have been detected with this version of the application.";
            }
        },
        viewContainerFindings(container: ScanResults) {
            this.containerToView = container;
            this.displayModal = true;
        },
        checkVulnerabilityScanSuccess(): boolean {
            const scanSuccess: boolean[] = [];
            this.containerScans.scanResults.map((container) => {
                if (
                    !container.findingSeverityCounts.CRITICAL &&
                    !container.findingSeverityCounts.HIGH &&
                    !container.findingSeverityCounts.MEDIUM &&
                    !container.findingSeverityCounts.LOW &&
                    !container.findingSeverityCounts.INFORMATIONAL &&
                    !container.findingSeverityCounts.UNDEFINED
                ) {
                    scanSuccess.push(true);
                } else {
                    scanSuccess.push(false);
                }
            });

            return scanSuccess.every((s: boolean) => s === true);
        },
    },
    created() {
        this.getContainerScans();
    },
});

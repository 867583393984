
import { Worklist, WorklistApplicationSummary } from "@/models/ApplicationWorklist";
import { defineComponent } from "vue";
import { DataOptions, DataTableHeader } from "vuetify";
import { getAppWorklist } from "@/api/WorklistService";
import { formatDateTime } from "@/utilities/date-utilities";
import { AdminRoute } from "@/router/route-names";
import { ScanProgress } from "@/models/Container";
import { debounce } from "underscore";

type WorklistTableData = {
    worklistPage: Worklist;
    worklist: WorklistApplicationSummary[];
    loading: boolean;
    tableOptions: DataOptions;
};

const headers: DataTableHeader[] = [
    {
        text: "Application Name",
        value: "name",
        sortable: false,
    },
    {
        text: "Version Number",
        value: "version_string",
        sortable: false,
    },
    {
        text: "Developer Name",
        value: "developer_name",
        sortable: false,
    },
    {
        text: "Status",
        value: "status",
        sortable: false,
    },
    {
        text: "Scan Status",
        value: "scan_status",
        sortable: false,
    },
    {
        text: "Submission Date",
        value: "submission_date",
        sortable: true,
    },
    {
        text: "Submission Type",
        value: "version_locked",
        sortable: false,
    },
    {
        text: "Action",
        value: "version_details_id",
        sortable: false,
    },
];

export default defineComponent({
    filters: {
        formatDateTime,
        chipStatusBg(status: ScanProgress) {
            if (status === ScanProgress.COMPLETE) {
                return "light-green lighten-4";
            } else if (status === ScanProgress.IN_PROGRESS) {
                return "blue lighten-5";
            } else if (status === ScanProgress.FAILED) {
                return "red lighten-5";
            }
        },
        chipStatusTextColour(status: ScanProgress) {
            if (status === ScanProgress.COMPLETE) {
                return "light-green darken-4";
            } else if (status === ScanProgress.IN_PROGRESS) {
                return "blue darken-4";
            } else if (status === ScanProgress.FAILED) {
                return "red darken-4";
            }
        },
        chipStatusText(status: ScanProgress) {
            if (status === ScanProgress.COMPLETE) {
                return "completed";
            } else if (status === ScanProgress.IN_PROGRESS) {
                return "in progress";
            } else if (status === ScanProgress.FAILED) {
                return "failed";
            }
        },
    },
    data(): WorklistTableData {
        return {
            worklistPage: { totalCount: 0, pageSize: 0, versions: [] } as Worklist,
            worklist: [],
            loading: true,
            tableOptions: {
                page: 1,
                itemsPerPage: 10,
                sortBy: ["submitted_date"],
                sortDesc: [true],
            } as DataOptions,
        };
    },
    computed: {
        applicationCount(): string {
            const count: number = this.$data.worklistPage.totalCount;

            if (count === 1) {
                return "1 Application";
            }

            return `${count} Applications`;
        },
    },
    setup() {
        return {
            headers,
        };
    },
    methods: {
        async getAdminWorklist() {
            this.loading = true;
            this.worklistPage = await getAppWorklist({
                page: this.tableOptions.page,
                itemsPerPage: this.tableOptions.itemsPerPage,
                sortBy: "submitted_date",
                sortDesc: this.tableOptions.sortDesc[0] ?? true,
            });
            this.worklist = this.worklistPage.versions;
            this.loading = false;
        },
        throttledFetchAdminWorklist: debounce(function () {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            this.getAdminWorklist();
        }, 250),
        viewApplicationSummary(application_id: string, version_id: string, version_details_id: string): void {
            this.$router.push({
                name: AdminRoute.applicationReview,
                query: {
                    application_id: application_id,
                    version_id: version_id,
                    version_details_id: version_details_id,
                },
            });
        },
    },

    watch: {
        tableOptions() {
            this.throttledFetchAdminWorklist();
        },
    },
});

import { Application, ApplicationFile } from "@/models/Application";
import { Developer } from "@/models/Developer";
import { CognitoUserSession } from "amazon-cognito-identity-js";
import Vue from "vue";
import Vuex from "vuex";
import VuexPersistence from "vuex-persist";
import createMutationsSharer from "vuex-shared-mutations";

export interface AppState {
    isAuthenticated: boolean;
    session?: CognitoUserSession;
    developerProfile?: Developer;
    roles: string[];
    accessToken: string;
    currentApplication: Application;
    currentApplicationFiles: ApplicationFile;
    currentApplicationRequiredFieldsFilled: boolean;
}

Vue.use(Vuex);

const vuexSession = new VuexPersistence<AppState>({
    storage: window.localStorage,
    reducer: (state) => ({
        accessToken: state.accessToken,
        roles: state.roles,
        developerProfile: state.developerProfile,
    }),
});

export default new Vuex.Store<AppState>({
    state: {
        isAuthenticated: false,
        accessToken: "",
        roles: [],
        currentApplication: {} as Application,
        currentApplicationFiles: {} as ApplicationFile,
        currentApplicationRequiredFieldsFilled: false,
    },
    getters: {
        isAuthenticated(state) {
            return state.isAuthenticated;
        },
    },
    mutations: {
        setAuthenticated(state, isAuthenticated: boolean) {
            state.isAuthenticated = isAuthenticated;
        },
        setSession(state, session?: CognitoUserSession) {
            state.session = session;
            state.accessToken = "";
            state.roles = [];

            const access = session?.getAccessToken();

            if (access) {
                state.accessToken = access.getJwtToken();

                const payload = access.decodePayload();
                state.roles = payload["cognito:groups"] ?? [];
            }
        },
        setDeveloperProfile(state, profile?: Developer) {
            state.developerProfile = profile;
        },
        setAccessToken(state, accessToken: string) {
            state.accessToken = accessToken;
        },
        setApplication(state, application: Application) {
            state.currentApplication = application;
        },
        setApplicationFiles(state, applicationFiles: ApplicationFile) {
            state.currentApplicationFiles = applicationFiles;
        },
        setCurrentApplicationRequiredFieldsFilled(state, filled: boolean) {
            state.currentApplicationRequiredFieldsFilled = filled;
        },
        setUserRoles(state, roles: string[]) {
            state.roles = roles ?? [];
        },
    },
    actions: {
        logout(context) {
            context.commit("setSession", null);
            context.commit("setAuthenticated", false);
            context.commit("setAccessToken", "");
            context.commit("setDeveloperProfile", null);
            window.sessionStorage.removeItem("currentPage");
        },
        clearCurrentApplication(context) {
            context.commit("setApplication", {});
            context.commit("setCurrentApplicationRequiredFieldsFilled", false);
        },
        clearCurrentApplicationFiles(context) {
            context.commit("setApplicationFiles", {});
            context.commit("setCurrentApplicationRequiredFieldsFilled", false);
        },
        createNewApplication(context) {
            const newApplication: Application = {
                name: "",
                version_string: "",
                long_desc: "",
                short_desc: "",
                version_id: "",
                version_details_id: "",
                intended_use: "",
                collaborators: [],
                certification_details: "",
                ce_certified: false,
                fda_certified: false,
                ukca_certified: false,
                medical_specialties: [],
            };

            context.commit("setApplication", newApplication);
        },
    },
    modules: {},
    plugins: [
        vuexSession.plugin,
        createMutationsSharer({ predicate: ["setUser", "setAuthenticated", "setSession", "setDeveloperProfile"] }),
    ],
});

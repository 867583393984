
import { defineComponent } from "vue";
import NamedSection from "../../NamedSection.vue";
import { alphanumericCharactersRules } from "@/utilities/validation";

export default defineComponent({
    components: {
        NamedSection,
    },
    computed: {
        destinationNameValue: {
            get() {
                return this.$props.destinationName;
            },
            set(value: string) {
                this.$emit("update:destinationName", value);
            },
        },
    },
    props: {
        destinationName: {
            type: String,
            required: true,
        },
    },
    emits: ["update:destinationName"],
    data() {
        return {
            validationRules: alphanumericCharactersRules,
        };
    },
});


import Vue from "vue";
import Component from "vue-class-component";
import { formatDate } from "@/utilities/date-utilities";

@Component({
    components: {},
    computed: {
        requiredFieldsFilledIn(): boolean {
            return true;
        },
    },
    filters: {
        formatDate,
    },
})
export default class PrivacyPolicy extends Vue {}


import { deleteApplicationFile } from "@/api/ApplicationFilesService";
import { generateUploadUrl, uploadFile, validateArgoWorkflow } from "@/api/FileUploadService";
import { defineComponent } from "vue";
import { InputValidationRules } from "vuetify";
import NamedSection from "@/components/publisher/NamedSection.vue";
import ConfirmationModal from "@/components/shared/ConfirmationModal.vue";
import { formatArgoWorkflowName } from "@/utilities/argo-workflow-helper";
import { fileToString } from "@/utilities/file-helpers";
import InformationContainer, { InformationContainerType } from "@/components/shared/InformationContainer.vue";
import { ContainerImage } from "@/models/Application";
import Vue from "vue";

type ComponentData = {
    selectedFile: File | null;
    deleteConfirm: boolean;
    requiredFieldsFilled: boolean;
    loading: boolean;
    validationErrors: string[];
    infoType: InformationContainerType;
    containerNamesInfoType: InformationContainerType;
    errorMessage: string;
};

const isYml = (name: string) => /\.y(a?)ml$/.test(name);

const validationRules: InputValidationRules = [
    (value: File | null) => !!value || "File is required",
    (value: File | null) => (value && value.size > 0) || "File is required",
    (value: File) => !value || isYml(value.name) || "Unsupported file type",
];

export default defineComponent({
    components: {
        NamedSection,
        ConfirmationModal,
        InformationContainer,
    },
    props: {
        applicationId: {
            type: String,
            required: true,
        },
        versionId: {
            type: String,
            required: true,
        },
        versionDetailsId: {
            type: String,
            required: true,
        },
        argoWorkflow: {
            type: String,
        },
        locked: {
            type: Boolean,
            required: true,
        },
    },
    computed: {
        containerNames(): string[] {
            return this.$store.state.currentApplication.container_repositories.map(
                (containerRepo: ContainerImage) => containerRepo.repositoryAddress
            );
        },
        argoWorkflowKeyValue(): string | undefined {
            return this.argoWorkflow;
        },
        displayFileInput(): boolean {
            if (!this.argoWorkflow) {
                return true;
            } else if (!this.selectedFile && !this.argoWorkflow.length) {
                return true;
            }

            return false;
        },
    },
    filters: {
        formatArgoWorkflowName,
    },
    setup() {
        return {
            validationRules,
        };
    },
    data(): ComponentData {
        return {
            selectedFile: null,
            deleteConfirm: false,
            requiredFieldsFilled: false,
            loading: false,
            validationErrors: [],
            infoType: InformationContainerType.WARNING,
            containerNamesInfoType: InformationContainerType.INFO,
            errorMessage: "",
        };
    },
    emits: ["update:argoWorkflow"],
    watch: {
        selectedFile: {
            async handler() {
                if (!this.selectedFile) {
                    this.requiredFieldsFilled = false;
                    return;
                }

                const { name } = this.selectedFile;

                if (!isYml(name)) {
                    this.requiredFieldsFilled = false;
                    return;
                }

                await this.fileUploaded(this.selectedFile);
            },
            deep: true,
            immediate: true,
        },
        requiredFieldsFilled: {
            handler() {
                this.$store.commit("setCurrentApplicationRequiredFieldsFilled", this.requiredFieldsFilled);
            },
            immediate: true,
        },
    },
    methods: {
        confirmDeletion() {
            this.deleteUploadedFile();
            this.deleteConfirm = false;
            this.$emit("update:argoWorkflow", "");
            this.selectedFile = null;
        },
        cancelFileDeletion() {
            this.deleteConfirm = false;
        },
        async fileUploaded(file: File) {
            if (!file) {
                return;
            }

            try {
                this.validationErrors = [];

                const newFile = new File(
                    [file],
                    `${this.$store.state.currentApplication.name}-${
                        this.$store.state.currentApplication.version_string
                    }.${file.name.split(".").pop()}`,
                    { type: file.type }
                );

                // Convert file to string format for workflow validation
                const fileAsString = await fileToString(file);

                const validateWorkflow = await validateArgoWorkflow(fileAsString, this.versionId);
                if (!validateWorkflow.valid) {
                    this.validationErrors = validateWorkflow.errors;
                    this.selectedFile = null;

                    return;
                }

                // Generate a pre-signed URL and push file to S3
                const [{ url, key }, error] = await generateUploadUrl(file.name);
                this.errorMessage = error;

                this.loading = true;

                await uploadFile(url, newFile);
                this.$emit("update:argoWorkflow", key);

                this.loading = false;
                this.requiredFieldsFilled = true;
            } catch (ex) {
                Vue.$toast.error("Something unexpected went wrong uploading the file, please retry");
                this.$emit("update:argoWorkflow", "");
                this.selectedFile = null;
                this.loading = false;
                this.requiredFieldsFilled = false;
            }
        },
        async deleteUploadedFile() {
            if (!this.argoWorkflow) {
                return;
            }

            await deleteApplicationFile(
                "argo-workflow",
                this.applicationId,
                this.versionId,
                this.versionDetailsId,
                this.argoWorkflow
            );

            this.$emit("update:argoWorkflow", "");
        },
    },
});

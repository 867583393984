
import { Application } from "@/models/Application";
import { formatDateAndTimeOfString } from "@/utilities/date-utilities";
import { defineComponent, PropType } from "vue";

export default defineComponent({
    filters: {
        formatDateAndTimeOfString,
    },
    props: {
        application: {
            type: Object as PropType<Application>,
            required: true,
        },
        selectedVersionId: {
            type: String,
            required: true,
        },
    },
});

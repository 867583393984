import { createAxiosInstance, createConfiguredAxiosInstance, ErrorMessageMap } from "@/utilities/axios-helpers";
import { HttpStatusCode, isAxiosError } from "axios";

export interface UploadUrlResponse {
    url: string;
    key: string;
}

export interface ValidateArgoWorkflowResponse {
    valid: boolean;
    errors: string[];
}

const errorMessages: ErrorMessageMap = {
    get: "Something unexpected went wrong retrieving an upload URL",
    post: "Something unexpected went wrong creating an upload URL, please retry",
    put: "Something unexpected went wrong uploading the file, please retry",
    delete: "Something unexpected went wrong deleting the file",
};

const http = createAxiosInstance(errorMessages);
const httpToastsHidden = createAxiosInstance(errorMessages, undefined, true);
const httpS3Upload = createConfiguredAxiosInstance(errorMessages, {
    axiosDefaults: {},
    authInterceptorEnabled: false,
    bearerInterceptorEnabled: false,
    allToastsHidden: true,
});

export async function getPresignedGetUrl(key: string): Promise<string> {
    const { data } = await http.get(`/file-uploads?key=${key}`);

    return data.url;
}

export async function generateUploadUrl(fileName: string): Promise<[UploadUrlResponse, string]> {
    let message = "";
    let result = {} as UploadUrlResponse;

    try {
        const response = await httpToastsHidden.post("/file-uploads", { fileName });
        result = response.data;
    } catch (error: unknown) {
        if (isAxiosError(error)) {
            const result = error.response;

            if (result?.status === HttpStatusCode.BadRequest) {
                message = result?.data.message;
            }
        }
    }

    return [result, message];
}

export async function uploadFile(url: string, file: File): Promise<void> {
    const headers = {
        "Content-Type": file.type,
    };

    const buffer = await file.arrayBuffer();
    await httpS3Upload.put(url, buffer, { headers });
}

export async function validateArgoWorkflow(
    fileAsString: string,
    versionId: string
): Promise<ValidateArgoWorkflowResponse> {
    const response = await http.post("/file-uploads/validate-argo-workflow", {
        content: fileAsString,
        versionId: versionId,
    });

    return response.data;
}

export interface ApplicationContainer {
    container_name: string;
    tags: string[];
}

export interface ContainerCommand {
    credentials: {
        command: string;
        authToken: string;
    };
    repositories: Repository[];
}

export interface Repository {
    createdAt: string;
    encryptionConfiguration: EncryptionConfig;
    imageScanningConfiguration: ImageScanningConfig;
    imageTagMutability: string;
    registryId: string;
    repositoryArn: string;
    repositoryName: string;
    repositoryUri: string;
}

export interface EncryptionConfig {
    encryptionType: string;
}

export interface ImageScanningConfig {
    scanOnPush: boolean;
}

export interface ContainerScan {
    status: ScanProgress;
    scanResults: ScanResults[];
}

export interface ScanResults {
    findingSeverityCounts: FindingSeverityCounts;
    findings: Findings[];
    repositoryName: string;
}

export interface FindingSeverityCounts {
    CRITICAL?: number;
    HIGH?: number;
    INFORMATIONAL?: number;
    LOW?: number;
    MEDIUM?: number;
    UNDEFINED?: number;
}

export interface Findings {
    attributes: Attribute[];
    description: string;
    name: string;
    severity: FindingSeverity;
    uri: string;
}

export interface Attribute {
    key: string;
    value: string;
}

export enum FindingSeverity {
    CRITICAL = "CRITICAL",
    HIGH = "HIGH",
    INFORMATIONAL = "INFORMATIONAL",
    LOW = "LOW",
    MEDIUM = "MEDIUM",
    UNDEFINED = "UNDEFINED",
}

export enum ScanProgress {
    COMPLETE = "COMPLETE",
    IN_PROGRESS = "IN_PROGRESS",
    FAILED = "FAILED",
}


import { Application } from "@/models/Application";
import { Developer } from "@/models/Developer";
import { defineComponent, PropType } from "vue";

export default defineComponent({
    props: {
        application: {
            type: Object as PropType<Application>,
            required: true,
        },
        developer: {
            type: Object as PropType<Developer>,
            required: true,
        },
    },
    data() {
        return {
            selectedVersion: this.application.version_string,
            items: [this.application.version_string],
        };
    },
});

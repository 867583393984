
import { Institution } from "@/models/Application";
import { VForm } from "@/utilities/v-form-helper";
import { defineComponent } from "vue";
import { PropType } from "vue/types/v3-component-props";

export default defineComponent({
    props: {
        institution: {
            type: Object as PropType<Institution>,
            required: true,
        },
        institutions: {
            type: Object as PropType<Institution[]>,
            required: true,
        },
    },
    computed: {
        form(): VForm {
            return this.$refs.form as VForm;
        },
        editing(): boolean {
            return !!this.institution?.id;
        },
        name: {
            get(): string | undefined {
                return this.institution?.name;
            },
            set(name: string) {
                this.institutionName = name;
            },
        },
    },
    data(props) {
        return {
            institutionName: "",
            requiredFieldsFilled: false,
            requiredTextRules: [
                (value: string) => !!value || "Required",
                (value: string) => value?.length <= 255 || "Names should be 255 characters or less",
                (value: string) =>
                    (!!value &&
                        !props.institutions.find(
                            (i: Institution) => i.name.toLowerCase().trim() === value.toLowerCase().trim()
                        )) ||
                    "Institution with this name already exists",
            ],
        };
    },
    emits: ["save", "cancel"],
    methods: {
        cancel() {
            this.$emit("cancel");
            this.resetForm();
        },
        save() {
            const isValid = this.form.validate();
            if (!isValid) {
                return false;
            }
            const institution = { ...(this.institution || {}), name: this.institutionName };
            this.$emit("save", institution);
        },
        resetForm() {
            this.institutionName = "";
            this.form.reset();
            this.form.resetValidation();
        },
    },
});


import store from "@/store";
import { logoutUser } from "@/utilities/amplify-auth";
import { defineComponent } from "vue";

export default defineComponent({
    computed: {
        pageTitle(): string {
            return this.$route.meta?.appHeaderTitle ?? "AIDE AppStore";
        },
        isAuthenticated(): boolean {
            return this.$store.state.isAuthenticated ?? false;
        },
    },
    data() {
        return {
            name: "",
        };
    },
    methods: {
        logout() {
            store.dispatch("logout");
            logoutUser();
        },
    },
});

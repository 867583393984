
import { EventBus } from "@/event-bus";
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import NamedSection from "../NamedSection.vue";

@Component({
    components: {
        NamedSection,
    },
})
export default class ApplicationBriefDescription extends Vue {
    @Prop() briefDescription!: string;
    validationRules = [
        (text: string): string | boolean => !!text || "Required",
        (text: string): string | boolean => text.length <= 150 || "Maximum 150 characters",
    ];

    error(event: string): void {
        EventBus.$emit("validationUpdate", "short_desc", event);
    }
}


import { defineComponent } from "vue";
import DeveloperHeader from "@/components/developer-profile/DeveloperHeader.vue";
import DeveloperName from "@/components/developer-profile/DeveloperName.vue";
import AboutDeveloper from "@/components/developer-profile/AboutDeveloper.vue";
import DeveloperLogo from "@/components/developer-profile/DeveloperLogo.vue";
import WebsiteLink from "@/components/developer-profile/WebsiteLink.vue";
import DeveloperFooter from "@/components/developer-profile/DeveloperFooter.vue";
import { Developer, DeveloperProfileStatus } from "@/models/Developer";
import { getDeveloper } from "@/api/DeveloperService";
import { Dictionary } from "vue-router/types/router";
import AppReviewContainer from "@/components/admin/application-review/AppReviewContainer.vue";
import { AdminRoute } from "@/router/route-names";
import { approveOrRejectDeveloperProfile } from "@/api/AdminReviewService";

type DeveloperProfileData = {
    developer: Developer;
    previousDeveloper: Developer;
    requiredFieldsFilledIn: boolean;
    loading: boolean;
    editing: boolean;
    developerExists: boolean;
    decisionReason: string;
};

export default defineComponent({
    metaInfo: {
        title: "Developer profile review",
    },
    components: {
        DeveloperHeader,
        DeveloperName,
        AboutDeveloper,
        DeveloperLogo,
        WebsiteLink,
        AppReviewContainer,
        DeveloperFooter,
    },
    data(): DeveloperProfileData {
        return {
            developer: {} as Developer,
            previousDeveloper: {} as Developer,
            requiredFieldsFilledIn: false,
            loading: false,
            editing: false,
            developerExists: false,
            decisionReason: "",
        };
    },
    created() {
        this.getDeveloper();
    },
    methods: {
        async getDeveloper() {
            const { developer_id } = this.$route.query as Dictionary<string>;

            this.developer = await getDeveloper(developer_id, DeveloperProfileStatus.SUBMITTED);
            this.previousDeveloper = await getDeveloper(developer_id, DeveloperProfileStatus.LIVE);
        },
        async approveRejectDeveloper(result: boolean) {
            const { developer_id, developer_profile_id } = this.$route.query as Dictionary<string>;
            const responseOk: boolean = await approveOrRejectDeveloperProfile(
                developer_id,
                developer_profile_id,
                result,
                this.decisionReason
            );

            if (responseOk) {
                this.$router.push({
                    name: AdminRoute.applicationWorklist,
                    query: {
                        developer_worklist: "true",
                    },
                });
            }
        },
    },
});

import { OutputType } from "@/models/Application";

export interface OutputTypesOption {
    text: string;
    value: OutputType;
}

export function mapToOutputTypeOptions(outputTypes: OutputType[]): OutputTypesOption[] | undefined {
    const formattedTypes = outputTypes.map((i) => ({ text: i.type, value: i }));
    if (formattedTypes.length === 0) {
        return undefined;
    }
    return formattedTypes;
}

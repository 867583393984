
import Vue from "vue";
import Component from "vue-class-component";

@Component({
    metaInfo: {
        title: "Unauthorised",
    },
    components: {},
})
export default class Unauthorized extends Vue {
    // Declared as component data
}

import router from "@/router";
import { ToastOptions } from "vue-toast-notification";
import Vue from "vue";

export function redirectToAppSummary(
    message: string,
    applicationId: string,
    versionId: string,
    versionDetailsId: string
) {
    const options: ToastOptions = {
        message,
        dismissible: true,
    };
    Vue.$toast.info(message, options);

    router.push({
        path: `/applications/application-summary?application_id=${applicationId}&version_id=${versionId}&version_details_id=${versionDetailsId}`,
        replace: true,
    });
}

import { createAxiosInstance, ErrorMessageMap, isResultOk } from "@/utilities/axios-helpers";

const errorMessageMapApp: ErrorMessageMap = {
    post: "Something unexpected went wrong with reviewing the application",
};
const httpApp = createAxiosInstance(errorMessageMapApp);

const errorMessageMapDev: ErrorMessageMap = {
    post: "Something unexpected went wrong reviewing the developer profile",
};
const httpDev = createAxiosInstance(errorMessageMapDev);

export async function approveOrRejectApplication(
    application_id: string,
    version_id: string,
    version_details_id: string,
    approve: boolean,
    reason?: string
): Promise<boolean> {
    const response = await httpApp.post("admin/review/application", {
        application_id: application_id,
        version_id: version_id,
        version_details_id: version_details_id,
        approve: approve,
        reason: reason,
    });
    return isResultOk(response);
}

export async function approveOrRejectDeveloperProfile(
    developer_id: string,
    developer_profile_id: string,
    approve: boolean,
    reason?: string
): Promise<boolean> {
    const response = await httpDev.post("admin/review/developer", {
        developer_id: developer_id,
        developer_profile_id: developer_profile_id,
        approve: approve,
        reason: reason,
    });
    return isResultOk(response);
}

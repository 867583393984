
import { AdminRoute } from "@/router/route-names";
import { defineComponent } from "vue";

export default defineComponent({
    methods: {
        handleBackButton() {
            this.$router.push({
                name: AdminRoute.applicationWorklist,
                query: {
                    developer_worklist: "true",
                },
            });
        },
    },
});


import { applicationAction, getAllApplicationSummaries } from "@/api/ApplicationSummariesService";
import { ApplicationSummaries, ApplicationSummary, Version } from "@/models/ApplicationSummary";
import Vue, { defineComponent } from "vue";
import { DataOptions } from "vuetify";
import VersionsModal from "./VersionsModal.vue";
import CreateNewVersionModal from "./CreateNewVersionModal.vue";
import { formatDateTime } from "@/utilities/date-utilities";
import { debounce } from "underscore";

export default defineComponent({
    components: { VersionsModal, CreateNewVersionModal },
    computed: {
        userHasDeveloperRole(): boolean {
            return this.$store.state.roles.includes("developer");
        },
    },
    filters: {
        formatDateTime,
    },
    data() {
        return {
            loading: true,
            newVersionModal: false,
            versionsModal: false,
            headers: [
                { text: "Application Name", value: "name", sortable: false },
                { text: "Created", value: "created", sortable: false },
                { text: "Updated", value: "updated", sortable: false },
                {
                    text: "Actions",
                    value: "actions",
                    width: "350px",
                    sortable: false,
                },
            ],
            tableOptions: {
                page: 1,
                itemsPerPage: 10,
            } as DataOptions,
            applicationSummary: {
                totalCount: 0,
                pageSize: 0,
                applications: [],
            } as ApplicationSummaries,
            selectedApplication: {} as ApplicationSummary,
        };
    },
    methods: {
        getLatestVersion(versions: Version[]) {
            if (versions && versions.length > 0) {
                return versions[versions.length - 1].version_string;
            } else {
                return "N/A";
            }
        },
        getLatestLiveVersion(versions: Version[]) {
            const liveVersion = versions.filter((version) => version.latest_version_details.status === "live");
            if (liveVersion && liveVersion.length > 0) {
                return liveVersion[liveVersion.length - 1].version_string;
            } else {
                return "N/A";
            }
        },
        getColorLiveVersion(versions: Version[]) {
            const liveVersion = versions.filter((version) => version.latest_version_details.status === "live");
            if (liveVersion && liveVersion.length > 0) {
                return "light-green lighten-4";
            } else {
                return "grey lighten-4";
            }
        },
        createNewVersion(application: ApplicationSummary) {
            this.selectedApplication = application;
            this.versionsModal = false;
            this.newVersionModal = true;
        },
        viewVersions(application: ApplicationSummary) {
            this.selectedApplication = application;
            this.versionsModal = true;
        },
        async fetchApplicationSummary() {
            this.loading = true;
            this.applicationSummary = await getAllApplicationSummaries({
                page: this.tableOptions.page,
                itemsPerPage: this.tableOptions.itemsPerPage,
                sortBy: "created_at",
                sortDesc: true,
            });

            // Update the prop value so the application is updated on the modal
            if (this.versionsModal) {
                this.selectedApplication = this.applicationSummary.applications.filter(
                    (a) => a.application_id === this.selectedApplication.application_id
                )[0];
            }

            this.loading = false;
        },
        throttledFetchApplicationSummaries: debounce(function () {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            this.fetchApplicationSummary();
        }, 250),
        async archiveApplication(application: ApplicationSummary) {
            const response = await applicationAction(application.application_id, "archive");
            if (response.status == 200) {
                Vue.$toast.success("Application version successfully discarded");
                this.throttledFetchApplicationSummaries();
            }
        },
        async recallApplication(application: ApplicationSummary) {
            const response = await applicationAction(application.application_id, "recall");
            if (response.status == 200) {
                Vue.$toast.success("Application version successfully discarded");
                this.throttledFetchApplicationSummaries();
            }
        },
        applicationIsLive(application: ApplicationSummary) {
            return application.versions.some((version) => {
                return version.version_is_live;
            });
        },
    },
    watch: {
        tableOptions() {
            this.throttledFetchApplicationSummaries();
        },
        status() {
            this.throttledFetchApplicationSummaries();
        },
    },
});


import { EventBus } from "@/event-bus";
import NamedSection from "../NamedSection.vue";
import { defineComponent } from "vue";

export default defineComponent({
    components: {
        NamedSection,
    },
    props: {
        applicationVersion: {
            type: String,
            required: true,
        },
        versionLocked: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            validationRules: [
                (text: string): string | boolean => !!text || "Required",
                (text: string): string | boolean => text.length <= 100 || "Maximum 100 characters",
                (text: string): string | boolean =>
                    /^[a-zA-Z0-9](.*[a-zA-Z0-9])?$/.test(text) || "Cannot start or end with a special character",
            ],
        };
    },
    methods: {
        error(event: string): void {
            EventBus.$emit("validationUpdate", "version_string", event);
        },
    },
});

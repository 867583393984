
import { EventBus } from "@/event-bus";
import { defineComponent, nextTick } from "vue";
import { PropType } from "vue/types/v3-component-props";
import NamedSection from "../NamedSection.vue";

const emailPattern =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const validationRules = [
    (text: string): string | boolean => text.length <= 100 || "Maximum 100 characters",
    (text: string): string | boolean => {
        if (text) {
            return emailPattern.test(text) || "Must be a valid email address";
        }

        return true;
    },
];

export default defineComponent({
    name: "ApplicationCollaborators",
    components: {
        NamedSection,
    },
    props: {
        applicationCollaborators: {
            type: Object as PropType<string[]>,
            required: true,
        },
    },
    data() {
        return {
            emailPattern,
            validationRules,
        };
    },
    computed: {
        applicationCollaboratorsValue: {
            get(): string[] {
                return this.applicationCollaborators;
            },
            set(collaborators: string[]): void {
                this.$emit("update:collaborators", collaborators);
            },
        },
    },
    methods: {
        addCollaborator(): void {
            const collaborators = [...this.applicationCollaboratorsValue];
            collaborators.push("");
            this.applicationCollaboratorsValue = collaborators;

            nextTick(() => {
                const inputs = this.$refs.collaboratorInput as HTMLInputElement[];
                const newInput = inputs[inputs.length - 1];
                newInput.focus();
            });
        },
        deleteCollaborator(index: number): void {
            this.applicationCollaboratorsValue.splice(index, 1);
        },
        error(event: string): void {
            EventBus.$emit("validationUpdate", "collaborators", event);
        },
        handleOnChange(value: string, index: number): void {
            const collaborators = [...this.applicationCollaboratorsValue];
            collaborators[index] = value;
            this.applicationCollaboratorsValue = collaborators;
        },
    },
});


import { defineComponent } from "vue";

export default defineComponent({
    computed: {
        modelValue: {
            get() {
                return this.$props.value;
            },
            set(value: string) {
                const numberValue = parseFloat(value);
                this.$emit("input", !isNaN(numberValue) ? numberValue : undefined);
            },
        },
    },
    props: {
        label: {
            type: String,
            required: true,
        },
        hint: {
            type: String,
            default: "",
        },
        placeholder: {
            type: String,
        },
        value: {
            type: Number,
        },
        min: {
            type: Number,
            required: true,
        },
        step: {
            type: Number,
            required: true,
        },
        required: {
            type: Boolean,
            required: true,
        },
        rules: {
            required: true,
            default: () => [],
        },
    },
});

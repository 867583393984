
import { defineComponent } from "vue";
export default defineComponent({
    props: {
        title: {
            type: String,
            required: true,
        },
        previous: {
            required: true,
        },
        current: {
            required: true,
        },
    },
    computed: {
        isArray(): boolean {
            return Array.isArray(this.$props.current);
        },
    },
    emits: ["close"],
    methods: {
        close() {
            this.$emit("close");
        },
    },
});


import DetailActionBar from "../DetailView/DetailActionBar.vue";
import DetailHeader from "../DetailView/DetailHeader.vue";
import IntendedUse from "../DetailView/IntendedUse.vue";
import ModelDetails from "../DetailView/ModelDetails.vue";
import CertificationDetails from "./CertificationDetails.vue";
import SystemRequirements from "../DetailView/SystemRequirements.vue";
import ApplicationFiles from "./ApplicationFiles.vue";
import VersionHistory from "../DetailView/VersionHistory.vue";
import AboutTheDeveloper from "../DetailView/AboutTheDeveloper.vue";
import { defineComponent } from "vue";
import { Application } from "@/models/Application";
import { Developer } from "@/models/Developer";

type ComponentData = {
    loading: boolean;
    application: Application | null;
    tab: number | null;
    selectedVersionID: string;
    latestAvailableVersion: string;
    applicationID: string;
    developer: Developer;
};

export default defineComponent({
    components: {
        DetailActionBar,
        DetailHeader,
        IntendedUse,
        ModelDetails,
        CertificationDetails,
        SystemRequirements,
        ApplicationFiles,
        VersionHistory,
        AboutTheDeveloper,
    },
    props: {
        heroImage: {
            type: String,
            required: true,
        },
    },
    data(): ComponentData {
        return {
            loading: true,
            application: this.$store.state.currentApplication,
            tab: null,
            selectedVersionID: "",
            latestAvailableVersion: "",
            applicationID: "",
            developer: this.$store.state.currentApplication.developer,
        };
    },
});

import { Auth } from "aws-amplify";
import { CognitoHostedUIIdentityProvider } from "@aws-amplify/auth";

const options = {
    authenticationFlowType: process.env.VUE_APP_COGNITO_RESPONSETYPE,
    region: process.env.VUE_APP_COGNITO_REGION,
    userPoolId: process.env.VUE_APP_COGNITO_USER_POOL_ID,
    userPoolWebClientId: process.env.VUE_APP_COGNITO_CLIENT_ID,
    oauth: {
        domain: process.env.VUE_APP_COGNITO_DOMAIN,
        redirectSignIn: process.env.VUE_APP_COGNITO_CALLBACK_URL,
        redirectSignOut: process.env.VUE_APP_COGNITO_LOGOUT_CALLBACK_URL,
        responseType: process.env.VUE_APP_COGNITO_RESPONSETYPE,
        scope: [],
    },
};

Auth.configure(options);

export function loginViaCognito() {
    return Auth.federatedSignIn({ provider: CognitoHostedUIIdentityProvider.Cognito });
}

export function logoutUser() {
    Auth.signOut({ global: true });
}

/**
 * This returns the current Cognito User Session
 * @returns Promise<CognitoUserSession>
 */
export function getCurrentSession() {
    return Auth.currentSession();
}

import { ApplicationFile } from "@/models/Application";
import { createAxiosInstance, ErrorMessageMap } from "@/utilities/axios-helpers";
import { decode } from "html-entities";

const errorMessageMap: ErrorMessageMap = {
    get: "Something unexpected went wrong retrieving application files",
    post: "Something unexpected went wrong saving application files",
    put: "Something unexpected went wrong with updating application files",
    delete: "Something unexpected went wrong deleting the file",
};

const http = createAxiosInstance(errorMessageMap);

export async function getApplicationFiles(
    application_id: string,
    version_id: string,
    version_details_id: string
): Promise<ApplicationFile> {
    const params = new URLSearchParams({ version_id, version_details_id });
    const response = await http.get(`/application-files/${application_id}?${params}`);
    return decodeApplicationFile(response.data);
}

function decodeApplicationFile(applicationFile: ApplicationFile) {
    applicationFile.files?.forEach((file) => (file.name = decode(file.name)));
    applicationFile.argo_workflow = decode(applicationFile.argo_workflow);
    applicationFile.hero_image = decode(applicationFile.hero_image);
    return applicationFile;
}

export async function updateApplicationFiles(
    application_id: string,
    version_id: string,
    version_details_id: string,
    applicationFiles: ApplicationFile
): Promise<boolean> {
    const params = new URLSearchParams({ version_id, version_details_id });

    const response = await http.post(`/application-files/${application_id}?${params}`, applicationFiles);
    return response.status === 200;
}

export async function deleteApplicationFile(
    kind: "hero" | "supporting" | "argo-workflow",
    application_id: string,
    version_id: string,
    version_details_id: string,
    key: string
): Promise<boolean> {
    const params = new URLSearchParams({ version_id, version_details_id, key });

    const response = await http.delete(`/application-files/${application_id}/${kind}?${params}`);
    return response.status === 200;
}


import {
    Application,
    IArtifactInput,
    IArtifactOutput,
    Institution,
    InstitutionAvailability,
} from "@/models/Application";
import { defineComponent, PropType } from "vue";
import ApplicationSummaryProperty from "./ApplicationSummaryProperty.vue";
import EditApplicationSectionButton from "./EditApplicationSectionButton.vue";
import InformationContainer from "@/components/shared/InformationContainer.vue";

export default defineComponent({
    components: {
        ApplicationSummaryProperty,
        EditApplicationSectionButton,
        InformationContainer,
    },
    props: {
        editable: {
            type: Boolean,
            default: true,
        },
        previousApplication: {
            type: Object as PropType<Application>,
            default: undefined,
        },
        hasValueChanged: {
            type: Function,
            default: () => false,
        },
    },
    computed: {
        sectionEditable(): boolean {
            return this.editable && this.$store.state.currentApplication.status !== "submitted";
        },
    },
    data() {
        return {
            requiredFieldsFilled: false,
        };
    },
    created() {
        this.checkIfrequiredFieldsFilled();
    },
    methods: {
        getViewableInstitutions(availability: InstitutionAvailability): string[] | undefined {
            // If the previous application does not exist, return undefined to not do any comparison on institutions
            if (availability?.only_selected === undefined) {
                return undefined;
            }

            if (availability?.only_selected === true) {
                // Return the name of the institutions for the modal, and the institutions object for the comparison
                return availability.viewable.map((i: Institution) => i.name);
            } else {
                return ["All institutions"];
            }
        },
        getInstallableInstitutions(availability: InstitutionAvailability): string[] | undefined {
            // If the previous application does not exist, return undefined to not do any comparison on institutions
            if (availability?.only_selected === undefined) {
                return undefined;
            }

            if (availability?.only_selected === true) {
                // Return the name of the institutions for the modal, and the institutions object for the comparison
                return availability.installable.map((i: Institution) => i.name);
            } else {
                return ["All institutions"];
            }
        },
        checkIfrequiredFieldsFilled() {
            const application = this.$store.state.currentApplication;
            if (
                !application.institution_availability ||
                !application.min_ram ||
                !application.min_cpu_cores ||
                !application.min_disk_space ||
                application.artifact_inputs.length === 0 ||
                application.artifact_outputs.length === 0
            ) {
                this.requiredFieldsFilled = false;
                this.$store.commit("setCurrentApplicationRequiredFieldsFilled", false);
            } else {
                this.requiredFieldsFilled = true;
            }
        },
        formatInputOutputDiffString(artifact: IArtifactInput | IArtifactOutput): string {
            let str = `name: ${artifact.name}, type: ${artifact.type}`;

            if ("body_part" in artifact) {
                str = str + `, body_part: ${artifact.body_part}`;
            }

            return str;
        },
    },
});


import { defineComponent } from "vue";
import NamedSection from "../../NamedSection.vue";
import { portRules } from "@/utilities/validation";

export default defineComponent({
    components: {
        NamedSection,
    },
    computed: {
        portValue: {
            get() {
                return this.$props.port;
            },
            set(value: string) {
                this.$emit("update:port", value);
            },
        },
    },
    props: {
        port: {
            type: Number,
            required: true,
        },
    },
    data() {
        return {
            validationRules: portRules,
        };
    },
    emits: ["update:port"],
});


import NamedSection from "../NamedSection.vue";
import { defineComponent } from "vue";

export default defineComponent({
    components: {
        NamedSection,
    },
    computed: {
        applicationNameValue: {
            get() {
                return this.$props.applicationName;
            },
            set(value: string) {
                this.$emit("update:applicationName", value);
            },
        },
    },
    props: {
        applicationName: {
            type: String,
            required: true,
        },
        applicationLocked: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            validationRules: [
                (text: string): string | boolean => !!text || "Required",
                (text: string): string | boolean => text.length <= 100 || "Maximum 100 characters",
                (text: string): string | boolean =>
                    /^[a-zA-Z0-9](.*[a-zA-Z0-9])?$/.test(text) || "Cannot start or end with a special character",
            ],
        };
    },
});


import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import { InputValidationRules } from "vuetify";
import { ApplicationFileItem, FileType } from "@/models/Application";

@Component({
    filters: {
        fileSize: (value: number): string => {
            const kb = 1024;
            const mb = kb ** 2; // 1024 * 1024
            const gb = kb ** 3; // 1024 * 1024 * 1024

            if (value < kb) {
                return `${value}B`;
            } else if (value < mb) {
                const kbv = value / kb;

                return `${kbv.toFixed(2)}KB`;
            } else if (value < gb) {
                const mbv = value / mb;

                return `${mbv.toFixed(2)}MB`;
            }

            const gbv = value / gb;

            return `${gbv.toFixed(2)}GB`;
        },
    },
    computed: {
        fileTypesSelection() {
            return (this.$props.fileTypes as FileType[]).map((f) => f.name);
        },
    },
})
export default class FilesTable extends Vue {
    menuProps = { contentClass: "file-type-dropdown" };
    requiredRules: InputValidationRules = [(value: File) => !!value || "Required"];

    @Prop({ default: () => [] })
    files!: ApplicationFileItem[];

    @Prop({ type: Boolean, default: false })
    editableType!: boolean;

    @Prop({ default: () => [] })
    fileTypes!: FileType[];

    @Prop({ required: true, type: Boolean })
    locked!: boolean;

    deleteClicked(file: ApplicationFileItem) {
        this.$emit("click:delete", file);
    }

    typeChanged(value: string, file: ApplicationFileItem) {
        this.$emit("change:type", value, file);
    }
}


import { Dictionary } from "vue-router/types/router";
import ConfirmationModal from "@/components/shared/ConfirmationModal.vue";
import { defineComponent } from "vue";

export default defineComponent({
    components: {
        ConfirmationModal,
    },
    props: {
        applicationSection: {
            type: String,
            required: true,
        },
        applicationSectionTitle: {
            type: String,
            required: true,
        },
    },
    computed: {
        userHasDeveloperRole(): boolean {
            return this.$store.state.roles.includes("developer");
        },
        applicationStatusRecalledOrArchived(): boolean {
            return (
                this.$store.state.currentApplication.status === "recalled" ||
                this.$store.state.currentApplication.status === "archived"
            );
        },
    },
    data() {
        return {
            dialogEditButtonDisplay: false,
        };
    },
    methods: {
        async handleEditConfirmed() {
            this.dialogEditButtonDisplay = false;
            const { application_id, version_id, version_details_id } = this.$route.query as Dictionary<string>;

            this.$router.push({
                name: this.applicationSection,
                query: {
                    application_id: application_id,
                    version_id: version_id,
                    version_details_id: version_details_id,
                    editing: "true",
                },
            });
        },
    },
});


import { Application } from "@/models/Application";
import { defineComponent, PropType } from "vue";

export default defineComponent({
    props: {
        application: {
            type: Object as PropType<Application>,
            required: true,
        },
    },
});


import { defineComponent } from "vue";
import { Dictionary } from "vue-router/types/router";
import { getApplication } from "@/api/ApplicationService";
import { Application } from "@/models/Application";
import { RemoteDetails } from "@/models/Application";
import InstitutionAvailability from "@/components/publisher/deployment-requirements/InstitutionAvailability.vue";
import SystemRequirements from "@/components/publisher/deployment-requirements/SystemRequirements.vue";
import InputTypes from "@/components/publisher/deployment-requirements/InputTypes.vue";
import OutputTypes from "@/components/publisher/deployment-requirements/OutputTypes.vue";
import RequiredFieldsMessage from "@/components/publisher/RequiredFieldsMessage.vue";
import NamedSection from "@/components/publisher/NamedSection.vue";
import DestinationName from "@/components/publisher/container-upload/dicom-destinations/DestinationName.vue";
import DestinationAETitle from "@/components/publisher/container-upload/dicom-destinations/DestinationAETitle.vue";
import DestinationAddress from "@/components/publisher/container-upload/dicom-destinations/DestinationAddress.vue";
import DestinationPort from "@/components/publisher/container-upload/dicom-destinations/DestinationPort.vue";

export default defineComponent({
    metaInfo: {
        title: "Application Requirements",
    },
    components: {
        NamedSection,
        DestinationName,
        DestinationAETitle,
        DestinationAddress,
        DestinationPort,
        InstitutionAvailability,
        SystemRequirements,
        InputTypes,
        OutputTypes,
        RequiredFieldsMessage,
    },
    data() {
        return {
            application: {} as Application,
            requiredFieldsFilled: false,
            loading: false,
            options: [
                {
                    id: "app-upload",
                    value: false,
                    text: "This application will be within the AIDE platform",
                },
                {
                    id: "remote-execution",
                    value: true,
                    text: "This application will be remote",
                },
            ],
            radioValidationRules: [(value: boolean | undefined | null) => typeof value === "boolean" || "Required"],
            destination: {} as RemoteDetails,
        };
    },
    created() {
        this.$store.commit("setCurrentApplicationRequiredFieldsFilled", false);

        if (this.application.application_type?.remote && this.application.application_type?.remote_details) {
            this.destination = this.application.application_type.remote_details;
        }
    },
    async mounted() {
        const { application_id, version_id, version_details_id } = this.$route.query as Dictionary<string>;

        this.loading = true;
        const application = await getApplication(application_id, version_id, version_details_id);
        this.application = application;
        this.loading = false;

        this.$store.commit("setApplication", application);
    },
    watch: {
        destination: {
            handler: function () {
                if (this.application.application_type && this.remoteAppValue) {
                    this.application.application_type = {
                        remote: this.remoteAppValue,
                        remote_details: this.destination,
                    };

                    this.$store.commit("setApplication", this.application);
                }
            },
            deep: true,
        },
        application() {
            this.$store.commit("setApplication", this.application);
        },
        requiredFieldsFilled() {
            this.$store.commit("setCurrentApplicationRequiredFieldsFilled", this.requiredFieldsFilled);
        },
    },
    computed: {
        remoteAppValue: {
            get() {
                return this.application.application_type?.remote ?? false;
            },
            set(value: boolean) {
                this.updateRemoteAppValue(value);
            },
        },
    },
    methods: {
        updateRemoteAppValue(value: boolean) {
            this.application = {
                ...this.application,
                application_type: {
                    ...this.application.application_type,
                    remote: value,
                },
            };

            this.$store.commit("setApplication", this.application);
        },
    },
});


import { defineComponent } from "vue";
import NamedSection from "../../NamedSection.vue";
import { aeTitleCharactersRules } from "@/utilities/validation";

export default defineComponent({
    components: {
        NamedSection,
    },
    computed: {
        AETitleValue: {
            get() {
                return this.$props.aeTitle;
            },
            set(value: string) {
                this.$emit("update:aeTitle", value);
            },
        },
    },
    props: {
        aeTitle: {
            type: String,
            required: true,
        },
    },
    emits: ["update:aeTitle"],
    data() {
        return {
            validationRules: aeTitleCharactersRules,
        };
    },
});


import Vue from "vue";
import Component from "vue-class-component";
import { Watch } from "vue-property-decorator";
import { Dictionary } from "vue-router/types/router";
import { Application } from "@/models/Application";
import ApplicationBriefDescription from "@/components/publisher/application-details/ApplicationBriefDescription.vue";
import ApplicationCollaborators from "@/components/publisher/application-details/ApplicationCollaborators.vue";
import ApplicationIntendedUse from "@/components/publisher/application-details/ApplicationIntendedUse.vue";
import ApplicationMedicalSpecialties from "@/components/publisher/application-details/ApplicationMedicalSpecialties.vue";
import ApplicationName from "@/components/publisher/application-details/ApplicationName.vue";
import ApplicationVersion from "@/components/publisher/application-details/ApplicationVersion.vue";
import ApplicationApplicationDetails from "@/components/publisher/application-details/ApplicationApplicationDetails.vue";
import ApplicationCertifications from "@/components/publisher/application-details/ApplicationCertifications.vue";
import RequiredFieldsMessage from "@/components/publisher/RequiredFieldsMessage.vue";
import { getApplication } from "@/api/ApplicationService";

@Component({
    metaInfo: {
        title: "Application Details",
    },
    components: {
        ApplicationName,
        ApplicationVersion,
        ApplicationCollaborators,
        ApplicationBriefDescription,
        ApplicationMedicalSpecialties,
        ApplicationIntendedUse,
        ApplicationApplicationDetails,
        ApplicationCertifications,
        RequiredFieldsMessage,
    },
})
export default class ApplicationDetails extends Vue {
    application: Application = {};
    requiredFieldsFilled = false;
    loading = false;

    created() {
        this.$store.dispatch("createNewApplication");
        this.application = this.$store.state.currentApplication;
    }

    async beforeMount() {
        const { application_id, version_id, version_details_id } = this.$route.query as Dictionary<string>;

        if (application_id && version_id && version_details_id) {
            this.loading = true;
            this.application = await getApplication(application_id, version_id, version_details_id);
            this.loading = false;
        }
    }

    @Watch("application", { deep: true })
    onApplicationChange(): void {
        this.$store.commit("setApplication", this.application);
    }

    @Watch("requiredFieldsFilled", { immediate: true })
    onRequiredFieldFilledIn(): void {
        this.$store.commit("setCurrentApplicationRequiredFieldsFilled", this.requiredFieldsFilled);
    }
}


import { defineComponent } from "vue";
import { Dictionary } from "vue-router/types/router";
import { ContainerCommand } from "@/models/Container";
import RequiredFieldsMessage from "@/components/publisher/RequiredFieldsMessage.vue";
import ApplicationExecutables from "@/components/publisher/container-upload/ApplicationExecutables.vue";
import ContainerUploadCommands from "@/components/publisher/container-upload/ContainerUploadCommands.vue";
import { getApplication } from "@/api/ApplicationService";
import { Application, ContainerImage } from "@/models/Application";
import { checkContainersUploaded, generateCommands } from "@/api/ApplicationContainersService";
import { redirectToAppSummary } from "./redirect";

type ComponentData = {
    applicationId: string;
    versionId: string;
    versionDetailsId: string;
    requiredFieldsFilled: boolean;
    containerImages: ContainerImage[] | undefined;
    containerCommands: ContainerCommand;
    containersUploaded: boolean;
    application: Application;
    showCommands: boolean;
    containerNamesChanged: boolean;
    loading: boolean;
};

export default defineComponent({
    name: "ContainerUpload",
    metaInfo: {
        title: "Application Container Upload",
    },
    components: {
        RequiredFieldsMessage,
        ApplicationExecutables,
        ContainerUploadCommands,
    },
    data(): ComponentData {
        return {
            applicationId: "",
            versionId: "",
            versionDetailsId: "",
            requiredFieldsFilled: false,
            containerImages: [],
            containerCommands: {} as ContainerCommand,
            containersUploaded: false,
            application: {} as Application,
            showCommands: false,
            containerNamesChanged: false,
            loading: false,
        };
    },
    computed: {
        remoteAppValue: {
            get() {
                return this.application.application_type?.remote ?? false;
            },
            set(value: boolean) {
                this.updateRemoteAppValue(value);
            },
        },
    },
    async created() {
        const { application_id, version_id, version_details_id } = this.$route.query as Dictionary<string>;

        this.applicationId = application_id;
        this.versionId = version_id;
        this.versionDetailsId = version_details_id;

        this.loading = true;
        this.application = await getApplication(this.applicationId, this.versionId, this.versionDetailsId);
        this.$store.commit("setApplication", this.application);

        this.containerImages = this.application.container_repositories;

        if (this.containerImages?.length && !this.application.version_locked) {
            const imageNames = this.containerImages?.map((image: ContainerImage) => image.name);
            this.containerCommands = await generateCommands(this.versionId, imageNames);

            const containersUploadedCheck = await checkContainersUploaded(this.versionId, imageNames);
            this.containersUploaded = containersUploadedCheck[0];
            this.requiredFieldsFilled = this.containersUploaded;
        } else if (this.application.version_locked) {
            const message = "Application is currently locked, you cannot edit the application containers.";
            redirectToAppSummary(message, application_id, version_id, version_details_id);
        }
        this.loading = false;
    },
    watch: {
        requiredFieldsFilled() {
            this.$store.commit("setCurrentApplicationRequiredFieldsFilled", this.requiredFieldsFilled);
        },
    },
    methods: {
        commandsGenerated(commands: ContainerCommand) {
            this.containerCommands = commands;
            this.requiredFieldsFilled = false;
        },
        showCommandsChange(show: boolean) {
            this.showCommands = show;
        },
        containerNamesChange(errorsExist: boolean) {
            this.containerNamesChanged = errorsExist;
            this.requiredFieldsFilled = !errorsExist;
        },
        containersUploadedChange(uploaded: boolean) {
            this.containersUploaded = uploaded;
            this.requiredFieldsFilled = uploaded;
        },
        updateRemoteAppValue(value: boolean) {
            this.application = {
                ...this.application,
                application_type: {
                    ...this.application.application_type,
                    remote: value,
                },
            };

            this.$store.commit("setApplication", this.application);
        },
    },
});


import { defineComponent, PropType } from "vue";
import { DataTableHeader } from "vuetify";
import { ScanResults } from "@/models/Container";

const headers: DataTableHeader[] = [
    {
        text: "Name",
        value: "name",
        sortable: false,
        align: "start",
        width: "150px",
    },
    {
        text: "Severity",
        value: "severity",
        sortable: false,
    },
    {
        text: "Description",
        value: "description",
        sortable: false,
    },
];

export default defineComponent({
    props: {
        containerFindings: {
            type: Object as PropType<ScanResults>,
            required: true,
        },
        displayModal: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        scanFindings() {
            return this.$props.containerFindings;
        },
        findingsModalDisplay: {
            get(): boolean {
                return this.$props.displayModal;
            },
            set(value: boolean) {
                this.$emit("update:displayModal", value);
            },
        },
    },
    setup() {
        return {
            headers,
        };
    },
});


import { defineComponent } from "vue";
import EditApplicationSectionButton from "./EditApplicationSectionButton.vue";
import InformationContainer from "@/components/shared/InformationContainer.vue";
import { Application } from "@/models/Application";

export default defineComponent({
    components: {
        EditApplicationSectionButton,
        InformationContainer,
    },
    props: {
        editable: {
            type: Boolean,
            default: true,
        },
    },
    computed: {
        requiredFieldsFilled() {
            const application: Application = this.$store.state.currentApplication;
            const hasContainers =
                !!application.container_repositories?.length &&
                application.container_repositories?.every((cr) => cr.tagName !== null);
            if (!hasContainers) {
                this.$store.commit("setCurrentApplicationRequiredFieldsFilled", hasContainers);
                return hasContainers;
            } else {
                return hasContainers;
            }
        },
        sectionEditable(): boolean {
            return this.editable && this.$store.state.currentApplication.status !== "submitted";
        },
    },
});


import { defineComponent } from "vue";
import DeveloperHeader from "@/components/developer-profile/DeveloperHeader.vue";
import DeveloperName from "@/components/developer-profile/DeveloperName.vue";
import AboutDeveloper from "@/components/developer-profile/AboutDeveloper.vue";
import DeveloperLogo from "@/components/developer-profile/DeveloperLogo.vue";
import WebsiteLink from "@/components/developer-profile/WebsiteLink.vue";
import RejectionInformation from "@/components/developer-profile/RejectionInformation.vue";
import InformationContainer from "@/components/shared/InformationContainer.vue";
import { Developer } from "@/models/Developer";
import { getDeveloperProfile } from "@/api/DeveloperService";
import store from "@/store";

type DeveloperProfileData = {
    developer: Developer;
    requiredFieldsFilledIn: boolean;
    loading: boolean;
    editing: boolean;
    developerExists: boolean;
    rejectionInfoClosed: boolean;
};

export default defineComponent({
    metaInfo: {
        title: "Developer Profile",
    },
    components: {
        DeveloperHeader,
        DeveloperName,
        AboutDeveloper,
        DeveloperLogo,
        WebsiteLink,
        InformationContainer,
        RejectionInformation,
    },
    data(): DeveloperProfileData {
        return {
            developer: {} as Developer,
            requiredFieldsFilledIn: false,
            loading: false,
            editing: false,
            developerExists: false,
            rejectionInfoClosed: false,
        };
    },
    created() {
        this.getDeveloper();
    },
    watch: {
        editing() {
            if (!this.editing) {
                this.getDeveloper();
            }
        },
    },
    methods: {
        async getDeveloper() {
            try {
                this.loading = true;
                this.developer = await getDeveloperProfile();
                this.developerExists = true;
                this.loading = false;

                store.commit("setDeveloperProfile", this.developer);
            } catch {
                this.developer = {
                    developer_name: "",
                    developer_about: "",
                    developer_logo_s3_file_id: "",
                    developer_url: "",
                } as Developer;
                this.developerExists = false;
                this.editing = true;
                this.loading = false;
            }
        },
    },
});

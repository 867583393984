
import { applicationVersionAction } from "@/api/ApplicationSummariesService";
import { ApplicationSummary, Version } from "@/models/ApplicationSummary";
import { PropType } from "vue";
import Vue, { defineComponent } from "vue";
import { formatDateTime } from "@/utilities/date-utilities";

export default defineComponent({
    filters: {
        formatDateTime,
        cannotRecall(status: string): boolean {
            return !["live", "archived"].includes(status);
        },
        getColour(version: Version): string {
            switch (version?.latest_version_details.status) {
                case "live":
                    return "light-green lighten-4";
                case "draft":
                    return "grey lighten-5";
                case "submitted":
                    return "blue lighten-5";
                default:
                    return "orange lighten-5";
            }
        },
    },
    props: {
        application: {
            type: Object as PropType<ApplicationSummary>,
            required: true,
        },
    },
    computed: {
        userHasDeveloperRole(): boolean {
            return this.$store.state.roles.includes("developer");
        },
    },
    data() {
        return {
            loading: false,
            headers: [
                { text: "Version String", value: "version_string", sortable: false },
                { text: "Status", value: "status", sortable: false },
                { text: "Updated", value: "updated", sortable: false },
                { text: "Actions", value: "actions", sortable: false },
            ],
            versions: [] as Version[],
            versionMenu: false,
        };
    },
    emits: ["cancel", "newVersion", "refreshApplications"],
    methods: {
        cancel() {
            this.$emit("cancel");
        },
        editVersion(version: Version) {
            this.$router.push({
                name: "ApplicationSummary",
                query: {
                    application_id: this.application.application_id,
                    version_id: version.version_id,
                    version_details_id: version.latest_version_details.version_details_id,
                },
            });
        },
        async archiveVersion(version: Version) {
            const response = await applicationVersionAction(
                this.application.application_id,
                version.version_id,
                "archive"
            );
            if (response.status == 200) {
                Vue.$toast.success("Application version successfully archived");
                this.$emit("refreshApplications");
                this.versionMenu = false;
                version.latest_version_details.status == "archived";
            }
        },
        async discardVersion(version: Version, index: number) {
            const response = await applicationVersionAction(
                this.application.application_id,
                version.version_id,
                "discard"
            );
            if (response.status == 200) {
                Vue.$toast.success("Application version successfully discarded");
                this.$emit("refreshApplications");
                this.versionMenu = false;
                this.versions.splice(index, 1);
                if (this.versions.length === 0) {
                    this.$emit("cancel");
                }
            }
        },
        async recallVersion(version: Version) {
            const response = await applicationVersionAction(
                this.application.application_id,
                version.version_id,
                "recall"
            );
            if (response.status == 200) {
                Vue.$toast.success("Application version successfully recalled");
                this.$emit("refreshApplications");
                this.versionMenu = false;
                version.latest_version_details.status == "recalled";
            }
        },
        createNewVersion() {
            this.$emit("newVersion");
        },
        openMenu() {
            this.versionMenu = true;
        },
    },
    mounted() {
        this.versions = this.application.versions;
    },
    watch: {
        application() {
            this.versions = this.application.versions;
        },
    },
});

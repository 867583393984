import Vue from "vue";
import VueMeta from "vue-meta";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import VueToast from "vue-toast-notification";
import numeral from "numeral";
import "./styles/toast.scss";

if (process.env.NODE_ENV === "development") {
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const { worker } = require("./__mocks__/_setup");
    await worker.start();

    worker.printHandlers();

    Vue.prototype.localRoles = {
        roles: ["admin", "developer"],
    };
}

Vue.config.productionTip = false;
Vue.use(VueToast, {
    position: "bottom-right",
});
Vue.use(VueMeta);

Vue.filter("formatNumber", function (value: string | number) {
    return numeral(value).format("0,0");
});

const app = new Vue({
    router,
    store,
    vuetify,
    render: (h) => h(App),
}).$mount("#app");

if (window.Cypress && process.env.VUE_APP_AUTH_ENABLED === "false") {
    // only available during E2E tests
    window.app = app;
}

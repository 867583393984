
import { IArtifactOutput, OutputType } from "@/models/Application";
import { defineComponent } from "vue";
import { PropType } from "vue/types/v3-component-props";
import { OutputTypesOption } from "./deployment-requirements-helper";

export default defineComponent({
    props: {
        index: {
            type: Number,
            required: true,
        },
        artifact: {
            type: Object as PropType<IArtifactOutput>,
            required: true,
        },
        options: {
            type: Array as PropType<OutputTypesOption[]>,
            default: () => [],
        },
    },
    data() {
        return {
            typesOptions: this.options,
            dropdownValidationRules: [
                (option: OutputTypesOption) => {
                    if (!option?.value?.type) {
                        return "Required";
                    }
                    return true;
                },
            ],

            nameValidationRules: [
                (value: string) => {
                    if (!value) {
                        return "Required";
                    }
                    if (value.length > 150) {
                        return "Maximum 150 characters";
                    }
                    return true;
                },
            ],
            menuProps: { contentClass: `output-type-dropdown-${this.index}` },
        };
    },
    computed: {
        nameValue: {
            get() {
                return this.artifact.name;
            },
            set(value: string) {
                const artifact: IArtifactOutput = {
                    ...this.artifact,
                    name: value,
                };

                this.$emit("update:artifact", artifact);
            },
        },
        typeValue: {
            get() {
                return {
                    text: this.artifact.type,
                    value: { id: this.artifact.id, type: this.artifact.type } as OutputType,
                };
            },
            set(value: OutputType) {
                const artifact: IArtifactOutput = {
                    ...this.artifact,
                    id: value.id,
                    type: value.type,
                };

                this.$emit("update:artifact", artifact);
            },
        },
    },
    methods: {
        deleteArtifact(): void {
            this.$emit("delete", this.index);
        },
    },
    emits: ["update:artifact", "delete"],
});


import { getPresignedGetUrl } from "@/api/FileUploadService";
import { ApplicationFile } from "@/models/Application";
import { defineComponent, PropType } from "vue";
import EditApplicationSectionButton from "./EditApplicationSectionButton.vue";
import InformationContainer from "@/components/shared/InformationContainer.vue";

export default defineComponent({
    components: {
        EditApplicationSectionButton,
        InformationContainer,
    },
    filters: {
        fileSize: (value: number): string => {
            const kb = 1024;
            const mb = kb ** 2; // 1024 * 1024
            const gb = kb ** 3; // 1024 * 1024 * 1024

            if (value < kb) {
                return `${value}B`;
            } else if (value < mb) {
                const kbv = value / kb;

                return `${kbv.toFixed(2)}KB`;
            } else if (value < gb) {
                const mbv = value / mb;

                return `${mbv.toFixed(2)}MB`;
            }

            const gbv = value / gb;

            return `${gbv.toFixed(2)}GB`;
        },
    },
    computed: {
        heroImageValue: {
            get(): string | undefined {
                return this.applicationFiles.hero_image;
            },
            set(key: string | undefined) {
                this.$emit("update:applicationFiles.hero_image", key);
            },
        },
        sectionEditable(): boolean {
            return this.editable && this.$store.state.currentApplication.status !== "submitted";
        },
    },
    props: {
        applicationFiles: {
            type: Object as PropType<ApplicationFile>,
            required: true,
        },
        editable: {
            type: Boolean,
            default: true,
        },
    },
    data() {
        return {
            requiredFieldsFilled: false,
            imageUrl: undefined as string | undefined,
        };
    },
    created() {
        this.checkIfRequiredFieldsFilled();
    },
    async mounted() {
        await this.loadImage();
    },
    watch: {
        async heroImageValue() {
            await this.loadImage();
        },
    },
    methods: {
        checkIfRequiredFieldsFilled() {
            if (!this.applicationFiles.hero_image) {
                this.requiredFieldsFilled = false;
                this.$store.commit("setCurrentApplicationRequiredFieldsFilled", false);
            } else {
                this.requiredFieldsFilled = true;
            }
        },
        async loadImage() {
            if (!this.heroImageValue) {
                this.imageUrl = undefined;

                return;
            }

            this.imageUrl = await getPresignedGetUrl(this.heroImageValue);
        },
        async downloadFile(key: string) {
            if (!key) {
                return;
            }

            const url = await getPresignedGetUrl(key);

            window.open(url);
        },
    },
});


import { defineComponent } from "vue";

export default defineComponent({
    props: {
        dataCyPrefix: {
            type: String,
            required: true,
        },
        persistent: {
            type: Boolean,
            default: true,
        },
        title: {
            type: String,
            default: "Confirm",
        },
        cancelBtnText: {
            type: String,
            default: "Cancel",
        },
        continueBtnText: {
            type: String,
            default: "Yes",
        },
        value: {
            type: Boolean,
            default: false,
        },
        deletionModal: {
            type: Boolean,
            default: false,
        },
        retainFocus: {
            type: Boolean,
            default: false,
        },
    },
    emits: ["cancel", "continue", "changeValue"],
    methods: {
        cancelAction() {
            this.$emit("cancel");
        },

        continueAction() {
            this.$emit("continue");
        },

        valueChanged(ev: boolean) {
            this.$emit("changeValue", ev);
        },
    },
});

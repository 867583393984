var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('NamedSection',{staticClass:"white mt-1 mb-0",attrs:{"title":"Certification","subtitle":"Which certifications has your application received?","description":"You can select more than one."}},[_c('v-row',{attrs:{"justify":"start"}},[_c('v-col',{attrs:{"md":"auto"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function({ hover }){return [_c('div',[_c('v-card',{staticClass:"mx-auto my-5 certification-card",class:{
                                active: _vm.ce_certified,
                            },attrs:{"elevation":hover ? 0 : 0,"data-cy":"ce-logo"},on:{"click":function($event){return _vm.$emit(`update:ce_certified`, !_vm.ce_certified)}}},[_c('v-img',{staticClass:"mx-auto",attrs:{"width":"80px","height":"80px","contain":"","src":require(`@/assets/CE.png`)}})],1),_c('v-row',{attrs:{"justify":"center"}},[_c('h4',{staticClass:"ma-0"},[_vm._v("CE")])])],1)]}}])})],1),_c('v-col',{attrs:{"md":"auto"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function({ hover }){return [_c('div',[_c('v-card',{staticClass:"mx-auto my-5 certification-card",class:{
                                active: _vm.ukca_certified,
                            },attrs:{"elevation":hover ? 0 : 0,"data-cy":"ukca-logo"},on:{"click":function($event){return _vm.$emit(`update:ukca_certified`, !_vm.ukca_certified)}}},[_c('v-img',{staticClass:"mx-auto",attrs:{"width":"80px","height":"80px","contain":"","src":require(`@/assets/UKCA.png`)}})],1),_c('v-row',{attrs:{"justify":"center"}},[_c('h4',{staticClass:"ma-0"},[_vm._v("UKCA")])])],1)]}}])})],1),_c('v-col',{attrs:{"md":"auto"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function({ hover }){return [_c('div',[_c('v-card',{staticClass:"mx-auto my-5 certification-card",class:{
                                active: _vm.fda_certified,
                            },attrs:{"elevation":hover ? 0 : 0,"data-cy":"fda-logo"},on:{"click":function($event){return _vm.$emit(`update:fda_certified`, !_vm.fda_certified)}}},[_c('v-img',{staticClass:"mx-auto",attrs:{"width":"80px","height":"80px","contain":"","src":require(`@/assets/FDA.png`)}})],1),_c('v-row',{attrs:{"justify":"center"}},[_c('h4',{staticClass:"ma-0"},[_vm._v("FDA")])])],1)]}}])})],1)],1)],1),_c('NamedSection',{staticClass:"white mb-1 mt-0",attrs:{"title":"Certification Details","description":"Please provide an overview of any certifications your application may have i.e. certificate number, date received etc."}},[_c('v-textarea',{staticClass:"mt-2",attrs:{"label":"Certification Details","outlined":"","dense":"","rows":"3","counter":"1000","rules":_vm.validationRules,"value":_vm.certifications,"data-cy":"certification-details-box"},on:{"input":function($event){return _vm.$emit(`update:certification_details`, $event)},"update:error":function($event){return _vm.error($event)}}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
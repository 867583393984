import moment from "moment";

// example:
// 27 Oct 2022 1.05pm
export const NhsDateTimeFormat = "DD-MMM-YYYY h:mma";

export function formatDateAndTimeOfTypedArray<T>(items: T[], itemProperty: keyof T, includeFromNow = true): T[] {
    return items.map((item: T) => ({
        ...item,
        [itemProperty]: formatDateAndTimeOfString(item[itemProperty] as string, includeFromNow),
    }));
}

/// Returns date time for example...
/// 27 Oct 2022 1.05pm (44 minutes ago)
/// 27 Oct 2022 1.05pm (20 years ago)
/// You can remove from now bit of string by passing in false to includeFromNow param.
export function formatDateAndTimeOfString(dateStr: string, includeFromNow = true) {
    const dateMoment = moment(dateStr);
    if (!dateMoment.isValid()) {
        return "";
    }
    return includeFromNow
        ? `${dateMoment.format(NhsDateTimeFormat)} (${dateMoment.fromNow()})`
        : `${dateMoment.format(NhsDateTimeFormat)}`;
}

export function formatDate(dateString: string, format = "DD-MMM-YYYY"): string {
    const date = moment(dateString);

    if (!date.isValid()) {
        return "";
    }

    return date.format(format);
}

export function formatDateTime(dateString: string, format = "DD-MMM-YYYY h:mma"): string {
    const date = moment(dateString);

    if (!date.isValid()) {
        return "";
    }

    return date.format(format);
}


import { defineComponent } from "vue";

export default defineComponent({
    props: {
        rejectionReason: {
            type: String,
            required: true,
        },
        rejectionInfoClosed: {
            type: Boolean,
            required: true,
        },
    },
    methods: {
        close() {
            this.$emit("update:rejectionInfoClosed", true);
        },
    },
});

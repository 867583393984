/**
 * Converts the type File to a string containing the contents of the file
 * @param {File} file - the file uploaded by the user
 * @returns the file's contents in string format
 */
export function fileToString(file: File): Promise<string> {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();

        reader.readAsText(file);

        reader.onload = () => {
            resolve(reader.result as string);
        };
        reader.onerror = (err) => {
            reject(err);
        };
    });
}


import { defineComponent } from "vue";
import WorklistTable from "@/components/admin/app-worklist/WorklistTable.vue";
import DeveloperApprovalTable from "@/components/admin/app-worklist/DeveloperApprovalTable.vue";

export default defineComponent({
    metaInfo: {
        title: "Approvals",
    },
    components: {
        WorklistTable,
        DeveloperApprovalTable,
    },
    data() {
        return {
            tabs: [
                { idx: "0", name: "Application Approval", key: "application-approval" },
                { idx: "1", name: "Developer Approval", key: "developer-approval" },
            ],
            currentTab: "0",
        };
    },
    beforeMount() {
        if (this.$route.fullPath.includes("developer_worklist")) {
            this.currentTab = "1";
        } else {
            this.currentTab = "0";
        }
    },
});


import { defineComponent, PropType } from "vue";
import NamedSection from "../NamedSection.vue";
import { getAllInputTypes } from "@/api/InputOutputTypeService";
import { InputType } from "@/models/Application";
import { IArtifactInput } from "../../../models/Application";
import { requiredTextRules } from "@/utilities/validation";

interface InputTypesOption {
    text: string;
    value: InputType;
}

function mapToInputTypeOptions(inputTypes: InputType[]): InputTypesOption[] | undefined {
    const formattedTypes = inputTypes.map((i) => ({ text: i.type, value: i }));
    if (formattedTypes.length === 0) {
        return undefined;
    }
    return formattedTypes;
}

export default defineComponent({
    components: {
        NamedSection,
    },
    props: {
        artifactInputs: {
            type: Array as PropType<IArtifactInput[]>,
            default: () => [],
        },
    },
    data() {
        return {
            typesOptions: [] as InputTypesOption[] | undefined,
            dropdownValidationRules: [
                (option: InputTypesOption) => {
                    if (!option?.value?.type) {
                        return "Required";
                    }
                    return true;
                },
            ],
            nameValidationRules: [
                (value: string) => {
                    if (!value) {
                        return "Required";
                    }
                    if (value.length > 150) {
                        return "Maximum 150 characters";
                    }
                    return true;
                },
            ],
            requiredTextRules,
            menuProps: { contentClass: "input-type-dropdown" },
            loading: false,
        };
    },
    computed: {
        nameValue: {
            get() {
                return this.artifactInputs.length > 0 ? this.artifactInputs[0].name : "";
            },
            set(value: string) {
                const artifact: IArtifactInput = {
                    ...this.artifactInputs[0],
                    name: value,
                };

                this.$emit("update:artifactInputs", [artifact]);
            },
        },
        typeValue: {
            get() {
                const id = this.artifactInputs.length > 0 ? this.artifactInputs[0].id : "";
                const type = this.artifactInputs.length > 0 ? this.artifactInputs[0].type : "";
                return {
                    text: type,
                    value: { id: id, type: type } as InputType,
                };
            },
            set(value: InputType) {
                const artifact: IArtifactInput = {
                    ...this.artifactInputs[0],
                    id: value.id,
                    type: value.type,
                };
                this.$emit("update:artifactInputs", [artifact]);
            },
        },
        bodyPartValue: {
            get() {
                return this.artifactInputs.length > 0 ? this.artifactInputs[0].body_part : "";
            },
            set(value: string) {
                const artifact: IArtifactInput = {
                    ...this.artifactInputs[0],
                    body_part: value,
                };

                this.$emit("update:artifactInputs", [artifact]);
            },
        },
    },
    async mounted() {
        this.loading = true;
        const options = await getAllInputTypes();
        this.typesOptions = mapToInputTypeOptions(options);

        this.loading = false;
    },
});

export interface Developer {
    developer_id: string;
    developer_profile_id: string;
    developer_name: string;
    developer_about: string;
    developer_logo_s3_file_id: string;
    developer_url: string;
    status: DeveloperProfileStatus;
    reason?: string;
}

export enum DeveloperProfileStatus {
    SUBMITTED = "SUBMITTED",
    ARCHIVED = "ARCHIVED",
    REJECTED = "REJECTED",
    LIVE = "LIVE",
}

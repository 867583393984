import { InputValidationRules } from "vuetify";

export const requiredTextRules: InputValidationRules = [(value: string) => !!value || "Required"];

export const numericCharactersRules: InputValidationRules = [
    (value: string) => {
        if (!value) {
            return "Required";
        }
        return /^[1-9]\d*$/.test(value) || "Only positive whole numbers are allowed";
    },
];

export const alphanumericCharactersRules: InputValidationRules = [
    (value: string) => {
        if (!value) {
            return "Required";
        }
        return /^[a-zA-Z0-9_]*$/.test(value) || "Only alphanumeric and underscores are allowed";
    },
];

export const aeTitleCharactersRules: InputValidationRules = [
    (value: string) => {
        if (!value) {
            return "Required";
        }
        if (!/^[^\\+^\s]*([^\\]+\s)*[^\\+\s]+$/.test(value)) {
            return "AE Title cannot contain backslash, leading or trailing spaces";
        }
        if (value.length > 13) {
            return "Maximum 13 characters";
        }
        return true;
    },
];

// https://stackoverflow.com/a/106223
export const ipAddressHostNameRules: InputValidationRules = [
    (value: string) => {
        if (!value) {
            return "Required";
        }
        return (
            /^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])$/.test(
                value
            ) ||
            /^(([a-zA-Z0-9]|[a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9])\.)*([A-Za-z0-9]|[A-Za-z0-9][A-Za-z0-9-]*[A-Za-z0-9])$/.test(
                value
            ) ||
            "Invalid address"
        );
    },
];

export const portRules: InputValidationRules = [
    (value: string) => {
        if (!value) {
            return "Required";
        }
        return (
            /^([1-9][0-9]{0,3}|[1-5][0-9]{4}|6[0-4][0-9]{3}|65[0-4][0-9]{2}|655[0-2][0-9]|6553[0-5])$/.test(value) ||
            "Invalid port. Must be between 1 and 65535"
        );
    },
];


import { EventBus } from "@/event-bus";
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import NamedSection from "../NamedSection.vue";

@Component({
    components: {
        NamedSection,
    },
})
export default class ApplicationApplicationDetails extends Vue {
    @Prop() applicationDetails!: string;
    validationRules = [
        (text: string): string | boolean => !!text || "Required",
        (text: string): string | boolean => text.length <= 1000 || "Maximum 1000 characters",
    ];

    error(event: string): void {
        EventBus.$emit("validationUpdate", "long_desc", event);
    }
}

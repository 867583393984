import { FileType } from "@/models/Application";
import { createAxiosInstance, ErrorMessageMap } from "@/utilities/axios-helpers";

const errorMessageMap: ErrorMessageMap = {
    get: "Something went wrong with retrieving the file types",
};

const http = createAxiosInstance(errorMessageMap);

export async function getAllFileTypes(): Promise<FileType[]> {
    const response = await http.get("/file-types");
    return response.status === 200 ? response.data : [];
}

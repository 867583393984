
import { getPresignedGetUrl } from "@/api/FileUploadService";
import { defineComponent } from "vue";

export default defineComponent({
    computed: {
        heroImageValue: {
            get(): string | undefined {
                return this.heroImage;
            },
            set(key: string | undefined) {
                this.$emit("update:heroImage", key);
            },
        },
    },
    props: {
        heroImage: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            medicalSpecialtyColours: ["#0b78cf", "#4646FF", "#a933ff", "#8227FF", "#00808f"],
            imageUrl: undefined as string | undefined,
        };
    },
    watch: {
        async heroImage() {
            await this.loadImage();
        },
    },
    async mounted() {
        await this.loadImage();
    },
    emits: ["open", "update:heroImage"],
    methods: {
        async loadImage() {
            if (!this.heroImage) {
                this.imageUrl = undefined;

                return;
            }

            this.imageUrl = await getPresignedGetUrl(this.heroImage);
        },
        open() {
            this.$emit("open");
        },
    },
});

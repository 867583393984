import Vue from "vue";
import axios, {
    AxiosError,
    AxiosInstance,
    AxiosResponse,
    CreateAxiosDefaults,
    InternalAxiosRequestConfig,
    HttpStatusCode,
} from "axios";
import { Store } from "vuex";
import store, { AppState } from "@/store";
import { getCurrentSession, loginViaCognito, logoutUser } from "@/utilities/amplify-auth";
import axiosRetry from "axios-retry";

export interface ErrorMessageMap {
    get?: string;
    post?: string;
    put?: string;
    patch?: string;
    delete?: string;
}

export interface ConfigurationOptions {
    axiosDefaults?: CreateAxiosDefaults;
    toastHidden?: boolean;
    authInterceptorEnabled?: boolean;
    bearerInterceptorEnabled?: boolean;
    toastInterceptorEnabled?: boolean;
    allToastsHidden?: boolean;
}

export interface ApiErrorResponse {
    statusCode: number;
    message: string;
}

export function isResultOk({ status }: AxiosResponse): boolean {
    return status >= 200 && status <= 299;
}

export function provideDefaultResult<T>(response: AxiosResponse<T>, defaultResult: T): T {
    return isResultOk(response) ? response.data : defaultResult;
}

export function authenticationEnabledInterceptor(
    config: InternalAxiosRequestConfig,
    store: Store<AppState>
): InternalAxiosRequestConfig {
    if (process.env.VUE_APP_AUTH_ENABLED === "true") {
        if (!store.state.accessToken) {
            loginViaCognito();
        } else {
            getCurrentSession().then(
                (session) => {
                    store.commit("setAuthenticated", true);
                    store.commit("setSession", session);
                },
                () => {
                    loginViaCognito();
                }
            );
        }
    }

    return config;
}

export function attachBearerTokenInterceptor(
    config: InternalAxiosRequestConfig,
    store: Store<AppState>
): InternalAxiosRequestConfig {
    if (process.env.VUE_APP_AUTH_ENABLED === "true" && !store.state.accessToken) {
        loginViaCognito();
    }

    if (config.headers && store.state.accessToken) {
        config.headers["Authorization"] = `Bearer ${store.state.accessToken}`;
    }

    return config;
}

export function onRequestErrorInterceptor(
    error: AxiosError,
    messageMap: ErrorMessageMap,
    store: Store<AppState>,
    toastHidden?: boolean,
    allToastsHidden?: boolean
): Promise<AxiosError> {
    if (error.response?.status === HttpStatusCode.Unauthorized) {
        store.dispatch("logout");
        logoutUser();

        return Promise.reject(error);
    } else if (error.response?.status === HttpStatusCode.NotFound && toastHidden) {
        return Promise.reject(error);
    } else if (error.code === "ERR_NETWORK") {
        Vue.$toast.error("Connection error. Please check your internet connection.");
        return Promise.reject(error);
    }

    if (!allToastsHidden) {
        const messageKey = `${error.request?.method || error.config?.method}`.toLowerCase() as keyof ErrorMessageMap;
        const message = messageMap[messageKey] ?? "Something unexpected went wrong.";

        Vue.$toast?.error(message);
    }

    return Promise.reject(error);
}

export function createAxiosInstance(
    errorMessages?: ErrorMessageMap,
    toastHidden?: boolean,
    allToastsHidden?: boolean
): AxiosInstance {
    const http = axios.create({
        baseURL: process.env.VUE_APP_API_URL,
        headers: {
            "Content-Type": "application/json",
        },
    });

    http.interceptors.request.use((config) => authenticationEnabledInterceptor(config, store));
    http.interceptors.request.use((config) => attachBearerTokenInterceptor(config, store));
    http.interceptors.response.use(
        (response) => response,
        (error) => onRequestErrorInterceptor(error, errorMessages ?? {}, store, toastHidden, allToastsHidden)
    );

    return http;
}

export function createConfiguredAxiosInstance(
    errorMessages?: ErrorMessageMap,
    configuration: ConfigurationOptions = {}
): AxiosInstance {
    const options: ConfigurationOptions = {
        axiosDefaults: {
            baseURL: process.env.VUE_APP_API_URL,
            headers: {
                "Content-Type": "application/json",
            },
        },
        authInterceptorEnabled: true,
        bearerInterceptorEnabled: true,
        toastInterceptorEnabled: true,
        toastHidden: false,
        allToastsHidden: false,
        ...configuration,
    };

    const http = axios.create(options.axiosDefaults);

    if (options.authInterceptorEnabled) {
        http.interceptors.request.use((config) => authenticationEnabledInterceptor(config, store));
    }

    if (options.bearerInterceptorEnabled) {
        http.interceptors.request.use((config) => attachBearerTokenInterceptor(config, store));
    }

    if (options.toastInterceptorEnabled) {
        http.interceptors.response.use(
            (response) => response,
            (error) =>
                onRequestErrorInterceptor(
                    error,
                    errorMessages ?? {},
                    store,
                    options.toastHidden,
                    options.allToastsHidden
                )
        );
    }

    axiosRetry(http, {
        retries: 1,
        retryDelay: () => 1000, // in milliseconds
        retryCondition: async (error) => {
            if (error.status !== HttpStatusCode.Unauthorized) {
                return false;
            }

            try {
                const session = await getCurrentSession();
                store.commit("setAuthenticated", true);
                store.commit("setSession", session);

                return true;
            } catch {
                return false;
            }
        },
    });

    return http;
}

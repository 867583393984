
import Vue from "vue";
import Component from "vue-class-component";
import { Watch } from "vue-property-decorator";
import RequiredFieldsMessage from "@/components/publisher/RequiredFieldsMessage.vue";
import ArgoWorkflow from "@/components/publisher/argo-workflow/ArgoWorkflow.vue";
import { Dictionary } from "vue-router/types/router";
import { Application, ApplicationFile } from "@/models/Application";
import { getApplicationFiles } from "@/api/ApplicationFilesService";
import { redirectToAppSummary } from "./redirect";
import { getApplication } from "@/api/ApplicationService";

@Component({
    metaInfo: {
        title: "Application Argo Workflow",
    },
    components: {
        RequiredFieldsMessage,
        ArgoWorkflow,
    },
})
export default class ArgoWorkflowPage extends Vue {
    requiredFieldsFilled = false;
    applicationFiles = {} as ApplicationFile;
    application = {} as Application;
    applicationId = "";
    versionId = "";
    versionDetailsId = "";
    loading = false;

    async created() {
        const { application_id, version_id, version_details_id } = this.$route.query as Dictionary<string>;

        this.applicationId = application_id;
        this.versionId = version_id;
        this.versionDetailsId = version_details_id;

        this.loading = true;

        this.application = await getApplication(application_id, version_id, version_details_id);
        this.$store.commit("setApplication", this.application);

        const applicationFiles = await getApplicationFiles(application_id, version_id, version_details_id);
        this.applicationFiles = applicationFiles;
        this.$store.commit("setApplicationFiles", applicationFiles);
        if (applicationFiles.version_locked ?? false) {
            const message = "Application is currently locked, you cannot edit the ARGO file.";
            redirectToAppSummary(message, application_id, version_id, version_details_id);
        }

        this.loading = false;
    }

    @Watch("applicationFiles", { deep: true })
    applicationFilesChanged() {
        this.$store.commit("setApplicationFiles", this.applicationFiles);
    }

    @Watch("requiredFieldsFilled", { immediate: true })
    onRequiredFieldFilledIn(): void {
        this.$store.commit("setCurrentApplicationRequiredFieldsFilled", this.requiredFieldsFilled);
    }
}

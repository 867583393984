import { Institution } from "@/models/Application";
import { createAxiosInstance, ErrorMessageMap, isResultOk } from "@/utilities/axios-helpers";
import { AxiosError, AxiosResponse } from "axios";
import { decode } from "html-entities";

const errorMessageMap: ErrorMessageMap = {
    get: "Something unexpected went wrong retrieving institutions",
};

const http = createAxiosInstance(errorMessageMap);

export async function getAllInstitutions(): Promise<Institution[]> {
    const response = await http.get("/institutions");
    return response.status === 200 ? decodeInstitutions(response.data) : [];
}

function decodeInstitutions(institutions: Institution[]) {
    institutions?.forEach((institution) => (institution.name = decode(institution.name)));
    return institutions;
}

export function updateInstitution(id: string, institution: Institution): Promise<AxiosResponse | AxiosError> {
    return http.put(`/institutions/${id}`, institution).catch((err) => err);
}

export function addInstitution(institution: Institution): Promise<AxiosResponse | AxiosError> {
    return http.post("/institutions", institution).catch((err) => err);
}

export async function deleteInstitution(id: string): Promise<boolean> {
    try {
        const result = await http.delete(`/institutions/${id}`);
        return isResultOk(result);
    } catch {
        return false;
    }
}

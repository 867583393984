
import { EventBus } from "@/event-bus";
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import NamedSection from "../NamedSection.vue";

@Component({
    components: {
        NamedSection,
    },
})
export default class ApplicationCertifications extends Vue {
    @Prop() certifications!: string;
    @Prop() ce_certified!: boolean;
    @Prop() fda_certified!: boolean;
    @Prop() ukca_certified!: boolean;
    validationRules = [(text: string): string | boolean => text.length <= 1000 || "Maximum 1000 characters"];

    error(event: string): void {
        EventBus.$emit("validationUpdate", "certification_details", event);
    }
}

export const ApplicationRoute = {
    dashboard: "Dashboard",
    publish: "Publish",
    logout: "Logout",
    unauthorised: "Unauthorized",
    policyPage: "Policy",
    developerProfile: "DeveloperProfile",
};

export const PublishRoute = {
    details: "ApplicationDetails",
    requirements: "DeploymentRequirements",
    files: "ApplicationFiles",
    containerUpload: "ContainerUpload",
    argoWorkflow: "ArgoWorkflowPage",
    summary: "ApplicationSummary",
};

export const AdminRoute = {
    institutionManagement: "InstitutionManagement",
    applicationWorklist: "ApplicationWorklist",
    applicationReview: "ApplicationReview",
    developerReview: "DeveloperReview",
};


import { defineComponent } from "vue";
import { DataOptions, DataTableHeader } from "vuetify";
import { formatDateTime } from "@/utilities/date-utilities";
import { AdminRoute } from "@/router/route-names";
import { PagedResponse } from "@/models/PagedResponse";
import { DeveloperProfile } from "@/models/DeveloperProfile";
import { getDevelopersForReview } from "@/api/WorklistService";
import { debounce } from "underscore";

type WorklistTableData = {
    tableData: PagedResponse<DeveloperProfile>;
    items: DeveloperProfile[];
    loading: boolean;
    tableOptions: DataOptions;
};

const headers: DataTableHeader[] = [
    {
        text: "Developer Name",
        value: "name",
        sortable: true,
    },
    {
        text: "Submission Date",
        value: "created_at",
        sortable: true,
    },
    {
        text: "Action",
        value: "action",
        sortable: false,
    },
];

export default defineComponent({
    filters: {
        formatDateTime,
    },
    data(): WorklistTableData {
        return {
            tableData: { totalCount: 0, pageSize: 0, items: [] } as PagedResponse<DeveloperProfile>,
            items: [],
            loading: true,
            tableOptions: {
                page: 1,
                itemsPerPage: 10,
                sortBy: ["created_at"],
                sortDesc: [true],
            } as DataOptions,
        };
    },
    computed: {
        developerCount(): string {
            const count: number = this.$data.tableData.totalCount;

            if (count === 1) {
                return "1 Developer";
            }

            return `${count} Developer profiles`;
        },
    },
    setup() {
        return {
            headers,
        };
    },
    methods: {
        async getDeveloperProfiles() {
            this.loading = true;
            this.tableData = await getDevelopersForReview({
                page: this.tableOptions.page,
                itemsPerPage: this.tableOptions.itemsPerPage,
                sortBy: this.tableOptions.sortBy[0] ?? "created_at",
                sortDesc: this.tableOptions.sortDesc[0] ?? true,
            });
            this.items = this.tableData.items;
            this.loading = false;
        },
        throttledFetchDeveloperProfiles: debounce(function () {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            this.getDeveloperProfiles();
        }, 250),
        viewDeveloperChangeRequest(developer_id: string, developer_profile_id: string): void {
            this.$router.push({
                name: AdminRoute.developerReview,
                query: {
                    developer_id,
                    developer_profile_id,
                },
            });
        },
    },
    watch: {
        tableOptions() {
            this.throttledFetchDeveloperProfiles();
        },
    },
});


import Vue from "vue";
import Component from "vue-class-component";
import { Watch } from "vue-property-decorator";
import { Dictionary } from "vue-router/types/router";
import NamedSection from "@/components/publisher/NamedSection.vue";
import HeroImage from "@/components/publisher/application-files/HeroImage.vue";
import RequiredFieldsMessage from "@/components/publisher/RequiredFieldsMessage.vue";
import SupportingFiles from "@/components/publisher/application-files/SupportingFiles.vue";
import { ApplicationFile } from "@/models/Application";
import { getApplicationFiles } from "@/api/ApplicationFilesService";
import { redirectToAppSummary } from "./redirect";

@Component({
    metaInfo: {
        title: "Application Files",
    },
    components: {
        HeroImage,
        NamedSection,
        RequiredFieldsMessage,
        SupportingFiles,
    },
})
export default class ApplicationFiles extends Vue {
    requiredFieldsFilled = false;
    applicationFiles = {} as ApplicationFile;
    applicationId = "";
    versionId = "";
    versionDetailsId = "";
    loading = false;

    async mounted() {
        const { application_id, version_id, version_details_id } = this.$route.query as Dictionary<string>;

        this.applicationId = application_id;
        this.versionId = version_id;
        this.versionDetailsId = version_details_id;

        this.loading = true;
        const applicationFiles = await getApplicationFiles(application_id, version_id, version_details_id);
        this.loading = false;

        if (applicationFiles.version_locked ?? false) {
            const message = "Application is currently locked, you cannot edit application files.";
            redirectToAppSummary(message, application_id, version_id, version_details_id);
        }

        this.applicationFiles = applicationFiles;
        this.$store.commit("setApplicationFiles", applicationFiles);
    }

    @Watch("applicationFiles", { deep: true })
    applicationFilesChanged() {
        this.$store.commit("setApplicationFiles", this.applicationFiles);
    }

    @Watch("requiredFieldsFilled", { immediate: true })
    onRequiredFieldFilledIn(): void {
        this.$store.commit("setCurrentApplicationRequiredFieldsFilled", this.requiredFieldsFilled);
    }
}

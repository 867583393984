import { ApplicationContainer, ContainerCommand, ContainerScan } from "@/models/Container";
import { createAxiosInstance, ErrorMessageMap, isResultOk } from "@/utilities/axios-helpers";

const errorMessages: ErrorMessageMap = {
    get: "Something unexpected went wrong retrieving application containers",
    post: "Something unexpected went wrong adding application containers",
    put: "Something unexpected went wrong updating application containers",
    delete: "Something unexpected went wrong deleting application containers",
};

const errorMessagesCommands: ErrorMessageMap = {
    post: "Something unexpected went wrong generating commands",
};

const errorMessagesContainers: ErrorMessageMap = {
    get: "Something unexpected went wrong confirming your containers were uploaded",
};

const http = createAxiosInstance(errorMessages);
const httpGenerateCommands = createAxiosInstance(errorMessagesCommands);
const httpCheckContainersUploaded = createAxiosInstance(errorMessagesContainers, true);

export async function generateCommands(version_id: string, imageNames: string[]): Promise<ContainerCommand> {
    const response = await httpGenerateCommands.post("/application-containers/generate", {
        containers: imageNames,
        versionId: version_id,
    });
    return response.data;
}

export async function getApplicationContainer(
    application_id: string,
    version_id: string,
    version_details_id: string
): Promise<ApplicationContainer[]> {
    const params = new URLSearchParams({ version_id, version_details_id });

    const response = await http.get(`/application-containers/${application_id}?${params}`);
    return response.data;
}

export async function getApplicationContainerTags(version_id: string): Promise<ApplicationContainer> {
    const response = await http.get(`/application-containers/tags/${version_id}`);
    return response.data;
}

export async function checkContainersUploaded(
    version_id: string,
    container_names: string[]
): Promise<[boolean, string, boolean]> {
    let message = "";
    let success = false;
    let userError = false;

    await httpCheckContainersUploaded
        .get(`/application-containers/${version_id}?containers=${container_names}`)
        .then((response) => {
            success = isResultOk(response);
            message = "";
        })
        .catch((error) => {
            if (error) {
                if (error.response.status === 404) {
                    userError = true;
                }
                message = error.response.data.message;
                return;
            }
        });
    return [success, message, userError];
}

export async function deleteApplicationContainer(version_id: string, application_name: string): Promise<boolean> {
    const response = await http.delete(`/application-containers/${version_id}/${application_name}`);
    return response.status === 200;
}

export async function getContainerScans(version_id: string): Promise<ContainerScan> {
    const response = await http.get(`/application-containers/scan-results/${version_id}`);
    return response.data;
}

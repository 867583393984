
import Vue from "vue";
import Component from "vue-class-component";
import { formatDate } from "@/utilities/date-utilities";
import { DataTableHeader } from "vuetify";

const headers: DataTableHeader[] = [
    {
        text: "Name",
        value: "name",
        sortable: false,
        width: "25%",
    },
    {
        text: "Use",
        value: "use",
        sortable: false,
        width: "75%",
    },
];

const items = [
    {
        name: "XSRF-TOKEN",
        use: "This is a security cookie used to prevent cross-site request forgery (CSRF) attacks. When a user makes a request to the website, this cookie is set with a unique value. When the request is sent, the server checks that the value in the cookie matches the value in the request. If the values don't match, the server assumes the request is forged and rejects it.",
    },
    {
        name: "csrf-state-legacy",
        use: "This is another security cookie used to prevent CSRF attacks and is a part of AWS Cognito OAuth.",
    },
    {
        name: "cognito",
        use: "This cookie is used by AWS Cognito. When a user logs in, this cookie is set to identify the user and help to manage access.",
    },
    {
        name: "csrf-state",
        use: "This is another security cookie used to prevent CSRF attacks and is a part of AWS Cognito OAuth. It works similarly to the XSRF-TOKEN cookie, but provides additional security features.",
    },
];

@Component({
    components: {},
    computed: {
        requiredFieldsFilledIn(): boolean {
            return true;
        },
    },
    filters: {
        formatDate,
    },
    setup() {
        return {
            headers,
            items,
        };
    },
})
export default class CookiePolicy extends Vue {}

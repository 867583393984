
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import { MedicalSpecialty } from "@/models/Application";
import { getAllMedicalspecialties } from "@/api/MedicalSpecialtyService";
import NamedSection from "../NamedSection.vue";
import { EventBus } from "@/event-bus";

@Component({
    components: {
        NamedSection,
    },
    computed: {
        localMedicalSpecialties: {
            get() {
                return this.$props.medicalSpecialties;
            },
            set(value: MedicalSpecialty[]) {
                this.$emit("update:medical_specialties", value);
            },
        },
    },
})
export default class ApplicationMedicalSpecialties extends Vue {
    @Prop() medicalSpecialties!: MedicalSpecialty[];
    displayError = false;
    loading = false;
    medicalSpecialtiesOptions: MedicalSpecialty[] = [];
    validationRules = [() => this.medicalSpecialties.length >= 1 || ""];

    async created() {
        this.loading = true;
        this.medicalSpecialtiesOptions = await getAllMedicalspecialties();
        this.loading = false;

        this.validationRules = [() => this.medicalSpecialties.length >= 1 || ""];
    }

    applicationContainsMedicalSpecialty(id: string) {
        return this.medicalSpecialties.find((m) => m.id === id) !== undefined;
    }

    validationError(event: string) {
        EventBus.$emit("validationUpdate", "medical_specialties", event);
    }
}


import { defineComponent } from "vue";
import NamedSection from "@/components/publisher/NamedSection.vue";
import { generateUploadUrl, getPresignedGetUrl, uploadFile } from "@/api/FileUploadService";
import { deleteApplicationFile } from "@/api/ApplicationFilesService";
import Vue from "vue";

const acceptedFileTypes = ["image/png", "image/jpeg", "image/jpg"];

export default defineComponent({
    components: {
        NamedSection,
    },
    props: {
        imageKey: {
            type: String,
            required: true,
            default: "",
        },
        applicationId: {
            type: String,
            required: true,
        },
        versionId: {
            type: String,
            required: true,
        },
        versionDetailsId: {
            type: String,
            required: true,
        },
        locked: {
            type: Boolean,
            required: true,
        },
    },
    data() {
        return {
            selectedFile: null as File | null,
            imageUrl: undefined as string | undefined,
            fileInvalid: false,
            fileUploading: false,
            replaceConfirmDialog: false,
            acceptedFileTypes,
            validationRules: [
                (value: File) => !!value || "Required",
                (value: File) => acceptedFileTypes.includes(value?.type) || "Unsupported file type",
                (value: File) => value?.size <= 5000000 || "Images should be 5MB or less",
            ],
            errorMessage: "",
        };
    },
    watch: {
        selectedFile: {
            async handler() {
                if (!this.selectedFile) {
                    return;
                }

                if (!acceptedFileTypes.includes(this.selectedFile.type)) {
                    this.fileInvalid = true;
                    return;
                }

                this.fileInvalid = false;
                await this.heroImageUploaded(this.selectedFile);
            },
            deep: true,
        },
        async imageKey() {
            await this.loadImage();
        },
    },
    async mounted() {
        await this.loadImage();
    },
    methods: {
        dropped(ev: DragEvent) {
            ev.preventDefault();

            if (!ev.dataTransfer) {
                return;
            }

            this.selectedFile = ev.dataTransfer.files.item(0);
        },
        dragOver(ev: DragEvent) {
            ev.preventDefault();

            if (!ev.dataTransfer) {
                return;
            }

            ev.dataTransfer.dropEffect = "copy";
        },
        async deleteUploadedImage() {
            if (!this.imageKey) {
                return;
            }

            await deleteApplicationFile(
                "hero",
                this.applicationId,
                this.versionId,
                this.versionDetailsId,
                this.imageKey
            );

            this.$emit("update:imageKey", "");
            this.selectedFile = null;
        },
        async heroImageUploaded(file: File) {
            try {
                const [{ url, key }, error] = await generateUploadUrl(file.name);
                this.errorMessage = error;
                this.fileUploading = true;

                await uploadFile(url, file);

                this.$emit("update:imageKey", key);
                this.fileUploading = false;
            } catch {
                Vue.$toast.error("Something unexpected went wrong uploading the file, please retry");
                this.$emit("update:imageKey", "");
                this.selectedFile = null;
                this.fileUploading = false;
            }
        },
        async replaceUploadedImage() {
            await this.deleteUploadedImage();
            this.replaceConfirmDialog = false;
        },
        async loadImage() {
            if (!this.imageKey) {
                this.imageUrl = undefined;

                return;
            }

            this.imageUrl = await getPresignedGetUrl(this.imageKey);
        },
    },
});


import { defineComponent } from "vue";
import InstitutionsTable from "@/components/admin/institutions/InstitutionsTable.vue";

export default defineComponent({
    metaInfo: {
        title: "Institutions",
    },
    components: {
        InstitutionsTable,
    },
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function compareValues(current: unknown, previous: unknown): boolean {
    if (Array.isArray(current) && Array.isArray(previous)) {
        const sortedCurrent = [...current].sort();
        const sortedPrevious = [...previous].sort();

        return JSON.stringify(sortedCurrent) !== JSON.stringify(sortedPrevious);
    }

    return current !== previous;
}


import { defineComponent } from "vue";

export enum InformationContainerType {
    INFO = "INFO",
    WARNING = "WARNING",
    SUCCESS = "SUCCESS",
}

export default defineComponent({
    props: {
        title: {
            type: String,
            required: false,
        },
        text: {
            type: String,
            required: false,
        },
        type: {
            type: String,
            required: true,
        },
    },
    filters: {
        backgroundColour(type: InformationContainerType) {
            if (type === InformationContainerType.SUCCESS) {
                return "light-green lighten-4";
            } else if (type === InformationContainerType.INFO) {
                return "blue lighten-5";
            } else if (type === InformationContainerType.WARNING) {
                return "red lighten-5";
            }
        },
        textColour(type: InformationContainerType) {
            if (type === InformationContainerType.SUCCESS) {
                return "light-green--text text--darken-4";
            } else if (type === InformationContainerType.INFO) {
                return "blue--text text--darken-4";
            } else if (type === InformationContainerType.WARNING) {
                return "red--text text--darken-4";
            }
        },
        icon(type: InformationContainerType) {
            if (type === InformationContainerType.SUCCESS) {
                return "mdi-check-circle-outline";
            } else if (type === InformationContainerType.INFO) {
                return "mdi-information-outline";
            } else if (type === InformationContainerType.WARNING) {
                return "mdi-alert-outline";
            }
        },
    },
});


import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import { InputValidationRules } from "vuetify";
import NamedSection from "@/components/publisher/NamedSection.vue";
import RequiredFieldsMessage from "@/components/publisher/RequiredFieldsMessage.vue";
import FilesTable from "@/components/publisher/application-files/FilesTable.vue";
import { ApplicationFileItem, FileType } from "@/models/Application";
import { getAllFileTypes } from "@/api/FileTypeService";
import { generateUploadUrl, uploadFile, UploadUrlResponse } from "@/api/FileUploadService";
import { deleteApplicationFile } from "@/api/ApplicationFilesService";
import ConfirmationModal from "@/components/shared/ConfirmationModal.vue";
import { AppState } from "@/store";

interface UploadInput {
    uploading: boolean;
}

@Component({
    components: {
        FilesTable,
        NamedSection,
        RequiredFieldsMessage,
        ConfirmationModal,
    },
    computed: {
        uploadedFilesValue: {
            get(): ApplicationFileItem[] {
                return this.$props.uploadedFiles ?? [];
            },
        },
        versionLocked: {
            get(): boolean {
                const state: AppState = this.$store.state;
                return state.currentApplicationFiles.version_locked ?? false;
            },
        },
    },
})
export default class SupportingFiles extends Vue {
    acceptedFileTypes = [
        "application/msword", // .doc
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document", // .docx
        "text/html", // .html / .htm
        "application/vnd.oasis.opendocument.text", // .odt
        "application/pdf", // .pdf
        "application/vnd.ms-excel", // .xls
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", // .xlsx
        "application/vnd.oasis.opendocument.spreadsheet", // .ods
        "application/vnd.ms-powerpoint", // .ppt
        "application/vnd.openxmlformats-officedocument.presentationml.presentation", // .pptx
        "text/plain", // .txt
    ];

    fileInvalid = false;

    fieldsRules: InputValidationRules = [
        (value: File) => !!value || "Required",
        (value: File) => this.acceptedFileTypes.includes(value?.type) || "Unsupported file type",
    ];
    fileTypes: FileType[] = [];
    supportingFilesInput: UploadInput[] = [];
    fileToDelete?: ApplicationFileItem;
    deleteConfirmDialog = false;
    loading = false;

    @Prop({ default: () => [] })
    uploadedFiles!: ApplicationFileItem[];

    @Prop({ required: true, type: String })
    applicationId!: string;

    @Prop({ required: true, type: String })
    versionId!: string;

    @Prop({ required: true, type: String })
    versionDetailsId!: string;

    async mounted() {
        this.loading = true;
        this.fileTypes = await getAllFileTypes();
        this.loading = false;
    }

    addSupportingFile() {
        this.supportingFilesInput.push({ uploading: false });
    }

    async fileUploaded(index: number, file: File) {
        if (!file) {
            return;
        }

        if (!this.acceptedFileTypes.includes(file.type)) {
            this.fileInvalid = true;
            return;
        }

        this.fileInvalid = false;

        let upload: [UploadUrlResponse, string] | undefined;

        try {
            upload = await generateUploadUrl(file.name);
            const uploadedFile: ApplicationFileItem = {
                s3_key: upload[0].key,
                name: file.name,
                size: file.size,
                status: "uploading",
                type: "",
                private: false,
            };

            this.$emit("update:uploadedFiles", [...this.uploadedFiles, uploadedFile]);

            await uploadFile(upload[0].url, file);
            uploadedFile.status = "uploaded";

            this.supportingFilesInput = this.supportingFilesInput.filter((_, i) => i !== index);
        } catch {
            Vue.$toast.error("Something unexpected went wrong uploading the file, please retry");
            this.supportingFilesInput = this.supportingFilesInput.splice(index, 0, { uploading: false });

            if (upload) {
                this.uploadedFiles.pop();
                this.$emit("update:uploadedFiles", this.uploadedFiles);
            }
        }
    }

    removeSupportingFileInput(index: number) {
        this.supportingFilesInput = this.supportingFilesInput.filter((_, i) => i !== index);
    }

    confirmDeletion(file: ApplicationFileItem) {
        this.fileToDelete = file;
        this.deleteConfirmDialog = true;
    }

    async deleteUploadedFile() {
        if (!this.fileToDelete) {
            return;
        }

        await deleteApplicationFile(
            "supporting",
            this.applicationId,
            this.versionId,
            this.versionDetailsId,
            this.fileToDelete.s3_key
        );

        this.deleteConfirmDialog = false;

        const updated = this.uploadedFiles.filter((a) => a.s3_key !== this.fileToDelete?.s3_key);
        this.fileToDelete = undefined;

        this.$emit("update:uploadedFiles", updated);
    }

    typeChanged(value: string, file: ApplicationFileItem) {
        const updated = this.uploadedFiles.find((a) => a.s3_key === file.s3_key);

        if (!updated) {
            return;
        }

        updated.type = value;

        this.$emit("update:uploadedFiles", this.uploadedFiles);
    }
}


import { Application } from "@/models/Application";
import { defineComponent } from "vue";
import ApplicationSummaryProperty from "./ApplicationSummaryProperty.vue";
import EditApplicationSectionButton from "./EditApplicationSectionButton.vue";
import InformationContainer from "@/components/shared/InformationContainer.vue";

export default defineComponent({
    components: {
        ApplicationSummaryProperty,
        EditApplicationSectionButton,
        InformationContainer,
    },
    props: {
        editable: {
            type: Boolean,
            default: true,
        },
    },
    computed: {
        sectionEditable(): boolean {
            return this.editable && this.application.status !== "submitted";
        },
    },
    data() {
        return {
            requiredFieldsFilled: false,
            application: {} as Application,
        };
    },
    created() {
        this.application = this.$store.state.currentApplication;
        this.checkIfRequiredFieldsFilled();
    },
    methods: {
        checkIfRequiredFieldsFilled() {
            if (
                !this.application.application_type?.remote_details?.name_of_destination ||
                !this.application.application_type.remote_details.ae_title ||
                !this.application.application_type.remote_details.address ||
                !this.application.application_type.remote_details.port
            ) {
                this.requiredFieldsFilled = false;
                this.$store.commit("setCurrentApplicationRequiredFieldsFilled", false);
            } else {
                this.requiredFieldsFilled = true;
            }
        },
    },
});

import { MedicalSpecialty } from "@/models/Application";
import { createAxiosInstance, ErrorMessageMap } from "@/utilities/axios-helpers";

const errorMessageMap: ErrorMessageMap = {
    get: "Something unexpected went wrong retrieving medical specialties",
};

const http = createAxiosInstance(errorMessageMap);

export async function getAllMedicalspecialties(): Promise<MedicalSpecialty[]> {
    const response = await http.get("/medical-specialties");
    return response.status === 200 ? response.data : [];
}

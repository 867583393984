
import { defineComponent } from "vue";
import NamedSection from "../../NamedSection.vue";
import { ipAddressHostNameRules } from "@/utilities/validation";

export default defineComponent({
    components: {
        NamedSection,
    },
    computed: {
        AddressValue: {
            get() {
                return this.$props.address;
            },
            set(value: string) {
                this.$emit("update:address", value);
            },
        },
    },
    props: {
        address: {
            type: String,
            required: true,
        },
    },
    emits: ["update:address"],
    data() {
        return {
            validationRules: ipAddressHostNameRules,
        };
    },
});


import Vue, { defineComponent } from "vue";
import ConfirmationModal from "@/components/shared/ConfirmationModal.vue";

export default defineComponent({
    props: {
        containerScanProgress: {
            type: String,
            required: false,
        },
        approveRejectType: {
            type: String,
            required: true,
        },
        decisionReason: {
            type: String,
            default: "",
        },
    },
    components: {
        ConfirmationModal,
    },
    computed: {
        decisionReasonValue: {
            get(): string | undefined {
                return this.$props.decisionReason;
            },
            set(reason: string) {
                this.$emit("update:decisionReason", reason);
            },
        },
    },
    data() {
        return {
            approve: false,
            confirmationModalDisplay: false,
            requiredFieldsFilled: false,
            validationRules: [] as ((text: string) => string | boolean)[],
        };
    },
    emits: ["reject", "approve", "update:decisionReason"],
    methods: {
        rejectAction() {
            this.$emit("reject");
        },
        approveAction() {
            this.$emit("approve");
        },
        openConfirmationModal(result: boolean) {
            this.approve = result;

            if (result === false && this.decisionReasonValue === "") {
                this.validationRules = [(text: string): string | boolean => !!text || "Required"];
                (this.$refs.form as Vue & { validate: () => boolean }).validate();
            } else {
                this.confirmationModalDisplay = true;
            }
        },
    },
});

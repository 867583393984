
import { Institution, InstitutionAvailability } from "@/models/Application";
import { getAllInstitutions } from "@/api/InstitutionsService";
import NamedSection from "../NamedSection.vue";
import { InputValidationRules } from "vuetify";
import { defineComponent, PropType } from "vue";

interface InstitutionOption {
    text: string;
    value: Institution;
}
function mapToInstitutionOptions(institutions: Institution[]): InstitutionOption[] {
    return institutions.map((i: Institution) => ({ text: i.name, value: i }));
}

type ComponentData = {
    options: { id: string; value: boolean; text: string }[];
    institutionsOptions: InstitutionOption[] | undefined;
    validationRules: InputValidationRules;
    radioValidationRules: InputValidationRules;
    menuPropsViewable: { contentClass: string };
    menuPropsInstallable: { contentClass: string };
    loading: boolean;
};

export default defineComponent({
    components: {
        NamedSection,
    },
    props: {
        institutionAvailability: {
            type: Object as PropType<InstitutionAvailability>,
            required: true,
            default: () => ({ only_selected: undefined, viewable: [], installable: [] }),
        },
    },
    computed: {
        onlySelectedValue: {
            get() {
                return this.$props.institutionAvailability.only_selected;
            },
            set(value: boolean) {
                const updated: InstitutionAvailability = {
                    viewable: value ? this.$props.institutionAvailability.viewable : [],
                    installable: value ? this.$props.institutionAvailability.installable : [],
                    only_selected: value,
                };
                this.$emit("update:institutionAvailability", updated);
            },
        },
        viewableInstitutionsValue: {
            get() {
                return mapToInstitutionOptions(this.$props.institutionAvailability.viewable ?? []);
            },
            set(institutions: Institution[]) {
                const selectedIds = institutions.map((io) => io.id);
                const selected = (this.$data.institutionsOptions as InstitutionOption[])
                    .filter((io) => selectedIds.includes(io.value.id))
                    .map((io) => io.value);

                const installable = this.$props.institutionAvailability.installable;
                installable.map((institution: Institution) => {
                    if (!selectedIds.includes(institution.id)) {
                        return installable.splice(installable.indexOf(institution), 1);
                    }
                });

                const updated: InstitutionAvailability = {
                    viewable: selected,
                    installable: installable,
                    only_selected: this.$props.institutionAvailability.only_selected,
                };
                this.$emit("update:institutionAvailability", updated);
                return updated;
            },
        },
        installableInstitutionsValue: {
            get() {
                return mapToInstitutionOptions(this.$props.institutionAvailability.installable ?? []);
            },
            set(institutions: Institution[]) {
                const selectedIds = institutions.map((io) => io.id);
                const selected = mapToInstitutionOptions(this.$props.institutionAvailability.viewable)
                    .filter((io) => selectedIds.includes(io.value.id))
                    .map((io) => io.value);

                const updated: InstitutionAvailability = {
                    viewable: this.$props.institutionAvailability.viewable,
                    installable: selected,
                    only_selected: this.$props.institutionAvailability.only_selected,
                };
                this.$emit("update:institutionAvailability", updated);
                return updated;
            },
        },
    },
    data(): ComponentData {
        return {
            options: [
                {
                    id: "all-institutions",
                    value: false,
                    text: "This application is available to all current and future institutions",
                },
                {
                    id: "selected-institutions",
                    value: true,
                    text: "This application is only available to selected institutions",
                },
            ],
            institutionsOptions: [],
            validationRules: [(value: Institution[]): string | boolean => value.length > 0 || "Required"],
            radioValidationRules: [(value: boolean | undefined | null) => typeof value === "boolean" || "Required"],
            menuPropsViewable: { contentClass: "viewable-institution-dropdown" },
            menuPropsInstallable: { contentClass: "installable-institution-dropdown" },
            loading: false,
        };
    },
    async mounted() {
        this.loading = true;
        const options = await getAllInstitutions();
        this.institutionsOptions = mapToInstitutionOptions(options);
        this.loading = false;
    },
});


import { defineComponent, PropType } from "vue";
import ApplicationSummaryProperty from "./ApplicationSummaryProperty.vue";
import EditApplicationSectionButton from "./EditApplicationSectionButton.vue";
import { Application } from "@/models/Application";
import InformationContainer from "@/components/shared/InformationContainer.vue";

export default defineComponent({
    components: {
        ApplicationSummaryProperty,
        EditApplicationSectionButton,
        InformationContainer,
    },
    props: {
        editable: {
            type: Boolean,
            default: true,
        },
        previousApplication: {
            type: Object as PropType<Application>,
            default: undefined,
        },
        hasValueChanged: {
            type: Function,
            default: () => false,
        },
    },
    computed: {
        sectionEditable(): boolean {
            return this.editable && this.$store.state.currentApplication.status !== "submitted";
        },
    },
    data() {
        return {
            requiredFieldsFilled: false,
        };
    },
    created() {
        this.checkIfrequiredFieldsFilled();
    },
    methods: {
        getCertifications(applicaton: Application) {
            const certifications = [];
            if (applicaton?.ce_certified === true) {
                certifications.push("CE");
            }
            if (applicaton?.ukca_certified === true) {
                certifications.push("UKCA");
            }
            if (applicaton?.fda_certified === true) {
                certifications.push("FDA");
            }

            return certifications;
        },
        checkIfrequiredFieldsFilled() {
            const application = this.$store.state.currentApplication;
            if (
                !application.name ||
                !application.version_string ||
                !application.long_desc ||
                !application.short_desc ||
                !application.intended_use ||
                application.medical_specialties.length === 0
            ) {
                this.requiredFieldsFilled = false;
                this.$store.commit("setCurrentApplicationRequiredFieldsFilled", false);
            } else {
                this.requiredFieldsFilled = true;
            }
        },
    },
});

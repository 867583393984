import { Application, ApplicationStatus, ContainerImage } from "@/models/Application";
import { createAxiosInstance, ErrorMessageMap } from "@/utilities/axios-helpers";
import { AxiosResponse } from "axios";
import { decode } from "html-entities";

const errorMessageMap: ErrorMessageMap = {
    get: "Something unexpected went wrong retrieving your application",
    post: "Something unexpected went wrong saving your application",
    put: "Something unexpected went wrong saving your application",
};

const http = createAxiosInstance(errorMessageMap);

export async function getApplication(
    application_id: string,
    version_id: string,
    version_details_id: string
): Promise<Application> {
    const params = new URLSearchParams({ version_id, version_details_id });
    const response = await http.get(`/applications/${application_id}?${params}`);
    return decodeApplication(response.data);
}

function decodeApplication(application: Application) {
    application.name = decode(application.name);
    application.version_string = decode(application.version_string);
    const decodedCollaborators: string[] = [];
    application.collaborators?.forEach((collaborator) => decodedCollaborators.push(decode(collaborator)));
    application.collaborators = decodedCollaborators;
    application.short_desc = decode(application.short_desc);
    application.intended_use = decode(application.intended_use);
    application.certification_details = decode(application.certification_details);
    application.long_desc = decode(application.long_desc);
    application.container_repositories?.forEach((repo) => (repo.name = decode(repo.name)));
    return application;
}

export async function createApplication(
    application: Application,
    application_id?: string
): Promise<AxiosResponse<Application>> {
    let requestPath = "/applications";

    if (application_id) {
        requestPath += `/${application_id}`;
    }

    return await http.post(requestPath, application, {
        validateStatus: (status: number) => (status >= 200 && status <= 299) || status === 409,
    });
}

export async function updateApplication(
    application_id: string,
    version_id: string,
    version_details_id: string,
    application: Application
): Promise<AxiosResponse<Application>> {
    const response = await http.put(
        `/applications/${application_id}?version_id=${version_id}&version_details_id=${version_details_id}`,
        application
    );
    return decodeUpdateApplicationResponse(response);
}

function decodeUpdateApplicationResponse(response: AxiosResponse) {
    response.data.name = decode(response.data.name);
    response.data.version_string = decode(response.data.version_string);
    const decodedCollaborators: string[] = [];
    response.data.collaborators?.forEach((collaborator: string) => decodedCollaborators.push(decode(collaborator)));
    response.data.collaborators = decodedCollaborators;
    response.data.short_desc = decode(response.data.short_desc);
    response.data.intended_use = decode(response.data.intended_use);
    response.data.certification_details = decode(response.data.intended_use);
    response.data.long_desc = decode(response.data.long_desc);
    response.data.container_repositories?.forEach((repo: ContainerImage) => (repo.name = decode(repo.name)));
    return response;
}

export async function updateApplicationStatus(
    application_id: string,
    version_id: string,
    version_details_id: string,
    application_status: string
): Promise<ApplicationStatus> {
    const response = await http.patch(
        `/applications/${application_id}?version_id=${version_id}&version_details_id=${version_details_id}&status=${application_status}`
    );

    return response.data;
}


import Vue, { defineComponent } from "vue";
import { routes } from "@/router";
import { AdminRoute, ApplicationRoute } from "@/router/route-names";
import store from "@/store";

type SidebarItem = {
    title: string;
    icon: string;
    dataCy: string;
    route: string;
    roles: string[];
};

const getRouteData = (route: string) => {
    const roles: string[] = routes.find((r) => r.name === route)?.meta?.requiredRoles ?? [];

    return {
        route,
        roles,
    };
};

const sidebarItems: SidebarItem[] = [
    {
        title: "Application Dashboard",
        icon: "mdi-apps",
        dataCy: "app-dashboard-button",
        ...getRouteData(ApplicationRoute.dashboard),
    },
    {
        title: "Application Worklist",
        icon: "mdi-list-box-outline",
        dataCy: "admin-worklist-button",
        ...getRouteData(AdminRoute.applicationWorklist),
    },
    {
        title: "Institutions Management",
        icon: "mdi-hospital-building",
        dataCy: "admin-institution-manage-button",
        ...getRouteData(AdminRoute.institutionManagement),
    },
    {
        title: "Developer Profile",
        icon: "mdi-account-outline",
        dataCy: "developer-profile-button",
        ...getRouteData(ApplicationRoute.developerProfile),
    },
];

export default defineComponent({
    computed: {
        shouldShowSidebar(): boolean {
            if (!store.state.roles.includes("developer")) {
                return true;
            }

            return !!store.state.developerProfile;
        },
        items(): SidebarItem[] {
            let roles: string[] = store.state.roles ?? [];

            if (process.env.NODE_ENV === "development" && !roles.length) {
                roles = Vue.prototype.localRoles.roles;
            }

            if (store.state.roles.includes("developer") && !store.state.developerProfile) {
                return [];
            }

            return roles.length === 0 ? [] : sidebarItems.filter((i) => roles.some((r) => i.roles.includes(r)));
        },
    },
});

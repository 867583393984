import { render, staticRenderFns } from "./VersionsModal.vue?vue&type=template&id=000d44db&scoped=true&"
import script from "./VersionsModal.vue?vue&type=script&lang=ts&"
export * from "./VersionsModal.vue?vue&type=script&lang=ts&"
import style0 from "./VersionsModal.vue?vue&type=style&index=0&id=000d44db&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "000d44db",
  null
  
)

export default component.exports

import { defineComponent } from "vue";

export default defineComponent({
    computed: {
        userHasDeveloperRole(): boolean {
            return this.$store.state.roles.includes("developer");
        },
    },
    methods: {
        viewDeveloperProfile(): void {
            this.$router.push({
                name: "DeveloperProfile",
            });
        },
        createNewApplication(): void {
            this.$router.push({
                name: "ApplicationDetails",
                params: { application_id: "test" },
            });
        },
    },
});


import { defineComponent } from "vue";
import { Application, ApplicationFile } from "@/models/Application";
import { getApplication } from "@/api/ApplicationService";
import { Dictionary } from "vue-router/types/router";
import ContainerScanTable from "@/components/admin/application-review/ContainerScanTable.vue";
import AboutApplicationSection from "@/components/publisher/application-summary/AboutApplicationSection.vue";
import DeploymentRequirementsSection from "@/components/publisher/application-summary/DeploymentRequirementsSection.vue";
import ApplicationFilesSection from "@/components/publisher/application-summary/ApplicationFilesSection.vue";
import ContainersSection from "@/components/publisher/application-summary/ContainersSection.vue";
import ApplicationCardPreviewSection from "@/components/publisher/application-summary/ApplicationCardPreviewSection.vue";
import { getApplicationFiles } from "@/api/ApplicationFilesService";
import ArgoWorkflowSection from "@/components/publisher/application-summary/ArgoWorkflowSection.vue";
import AppReviewContainer from "@/components/admin/application-review/AppReviewContainer.vue";
import { getContainerScans } from "@/api/ApplicationContainersService";
import { ContainerScan } from "@/models/Container";
import { compareValues } from "./admin-utils";
import { Version } from "@/models/ApplicationSummary";
import { getLiveVersionsForApplicationSummary } from "@/api/ApplicationSummariesService";
import { approveOrRejectApplication } from "@/api/AdminReviewService";
import { AdminRoute } from "@/router/route-names";
import RemoteApplicationSection from "@/components/publisher/application-summary/RemoteApplicationSection.vue";

interface LiveVersionOptions {
    text: string;
    value: Version;
}

interface ApplicationReviewData {
    application: Application;
    applicationFiles: ApplicationFile;
    liveVersionsOptions: LiveVersionOptions[] | undefined;
    previousApplicationValue: Application | undefined;
    previousApplicationFiles: ApplicationFile | undefined;
    loading: boolean;
    applicationId: string;
    versionId: string;
    versionDetailsId: string;
    containerScans: ContainerScan;
    menuProps: unknown;
    decisionReason: string;
}

function mapToLiveVersionOptions(versions: Version[]): LiveVersionOptions[] | undefined {
    const formattedTypes = versions.map((v) => ({ text: v.version_string, value: v }));
    if (formattedTypes.length === 0) {
        return undefined;
    }
    return formattedTypes;
}

export default defineComponent({
    metaInfo: {
        title: "Application Review",
    },
    components: {
        ContainerScanTable,
        AboutApplicationSection,
        ArgoWorkflowSection,
        DeploymentRequirementsSection,
        ApplicationFilesSection,
        ContainersSection,
        ApplicationCardPreviewSection,
        AppReviewContainer,
        RemoteApplicationSection,
    },
    data(): ApplicationReviewData {
        return {
            application: {} as Application,
            applicationFiles: {} as ApplicationFile,
            liveVersionsOptions: [] as LiveVersionOptions[] | undefined,
            previousApplicationValue: undefined,
            previousApplicationFiles: undefined,
            loading: false,
            applicationId: "",
            versionId: "",
            versionDetailsId: "",
            containerScans: {} as ContainerScan,
            menuProps: { contentClass: "previous-application-dropdown" },
            decisionReason: "",
        };
    },
    methods: {
        getPreviousApplicationFilesArgoWorkflow(): undefined | string {
            if (this.previousApplicationFiles === undefined) return;
            return (this.previousApplicationFiles as ApplicationFile)?.argo_workflow;
        },
        hasValueChanged(current: unknown, previous: unknown, optional = false): boolean {
            if (previous === undefined) {
                return false;
            }

            if (Array.isArray(previous) && !optional) {
                if (previous?.every((i: unknown) => i === undefined)) {
                    return false;
                }
            }

            return compareValues(current, previous);
        },
        async handleCompareVersions(version: Version) {
            const { application_id } = this.$route.query as Dictionary<string>;

            this.previousApplicationValue = await getApplication(
                application_id,
                version.version_id,
                version.live_version_details_id
            );

            this.previousApplicationFiles = await getApplicationFiles(
                application_id,
                version.version_id,
                version.live_version_details_id
            );
        },
        async approveRejectApplication(result: boolean) {
            const { application_id, version_id, version_details_id } = this.$route.query as Dictionary<string>;
            const responseOk: boolean = await approveOrRejectApplication(
                application_id,
                version_id,
                version_details_id,
                result,
                this.decisionReason
            );

            if (responseOk) {
                this.$router.push({
                    name: AdminRoute.applicationWorklist,
                });
            }
        },
    },
    async created() {
        const { application_id, version_id, version_details_id } = this.$route.query as Dictionary<string>;
        this.applicationId = application_id;
        this.versionId = version_id;
        this.versionDetailsId = version_details_id;

        this.loading = true;

        this.application = await getApplication(this.applicationId, this.versionId, this.versionDetailsId);
        this.$store.commit("setApplication", this.application);

        this.applicationFiles = await getApplicationFiles(application_id, version_id, version_details_id);
        this.containerScans = await getContainerScans(this.versionId);

        const options = await getLiveVersionsForApplicationSummary(application_id);
        this.liveVersionsOptions = options ? mapToLiveVersionOptions(options?.versions) : undefined;

        this.loading = false;
    },
});

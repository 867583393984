import { Application } from "@/models/Application";
import { ApplicationSummaries, ApplicationSummary } from "@/models/ApplicationSummary";
import { createAxiosInstance, ErrorMessageMap } from "@/utilities/axios-helpers";
import { AxiosResponse } from "axios";
import { decode } from "html-entities";

const errorMessageMap: ErrorMessageMap = {
    get: "Something unexpected went wrong retrieving your applications",
    post: "Something unexpected went wrong updating your application",
    put: "Something unexpected went wrong saving your application",
};

const previousApplicationsErrorMessageMap: ErrorMessageMap = {
    get: "Something went wrong when retrieving your previous application versions",
};

const http = createAxiosInstance(errorMessageMap);
const previousApplicationsHttp = createAxiosInstance(previousApplicationsErrorMessageMap);

interface QueryParams {
    page: number;
    itemsPerPage: number;
    sortBy: string;
    sortDesc: boolean;
}

export async function getAllApplicationSummaries(query: QueryParams): Promise<ApplicationSummaries> {
    const params = new URLSearchParams({
        pageNumber: `${query.page}`,
        pageSize: `${query.itemsPerPage}`,
        sortBy: query.sortBy ?? "",
        sortDesc: `${query.sortDesc}`,
    });

    const response = await http.get(`/application-summaries?${params}`);
    return decodeApplicationSummaries(response.data);
}

function decodeApplicationSummaries(summaries: ApplicationSummaries) {
    summaries.applications?.forEach((summary) => (summary.name = decode(summary.name)));
    return summaries;
}

export async function getLiveVersionsForApplicationSummary(
    application_id: string
): Promise<ApplicationSummary | undefined> {
    try {
        const response = await previousApplicationsHttp.get(`/application-summaries/${application_id}/live`);

        return response.data;
    } catch (e) {
        return undefined;
    }
}

export async function createNewApplicationVersion(
    application_id: string,
    version_string: string
): Promise<Application> {
    const response = await http.post(`/applications/${application_id}?version_string=${version_string}`);

    return response.data;
}

export async function applicationVersionAction(
    application_id: string,
    version_id: string,
    action: string
): Promise<AxiosResponse> {
    const response = await http.post(
        `/admin/application-status/${application_id}?version_id=${version_id}&action=${action}`
    );
    return response;
}

export async function applicationAction(application_id: string, action: string): Promise<AxiosResponse> {
    const response = await http.put(`/admin/application-status/${application_id}?action=${action}`);
    return response;
}


import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";

@Component
export default class NamedSection extends Vue {
    @Prop({ type: String, default: "MISSING TITLE" }) title!: string;
    @Prop() subtitle: string | undefined;
    @Prop() description: string | undefined;
    @Prop() columnStyling: string | undefined;
    @Prop({ type: Boolean, default: false }) required!: boolean;
}

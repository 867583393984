
import Vue from "vue";
import Component from "vue-class-component";
import { formatDate } from "@/utilities/date-utilities";

@Component({
    filters: {
        formatDate,
    },
})
export default class AccessabilityStatement extends Vue {
    statementPreparedDate = "2023-04-05T00:00:00";
    statementLastReviewedDate = "2023-04-05T00:00:00";
    websiteLastTestedDate = "2023-04-05T00:00:00";
}

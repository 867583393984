
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";

@Component({
    computed: {
        supportsClipboard() {
            return !!window.navigator.clipboard;
        },
    },
})
export default class CommandStep extends Vue {
    @Prop({ required: true })
    step!: string;

    @Prop({ required: true })
    label!: string;

    @Prop({ type: String })
    hint?: string;

    @Prop({ required: true })
    command!: string;

    @Prop({ default: false })
    hideStep?: boolean;

    @Prop({ type: String })
    dataCy!: string;

    copied = false;

    copyCommand() {
        this.copied = true;

        window.navigator.clipboard.writeText(this.command);

        setTimeout(() => {
            this.copied = false;
        }, 1000);
    }
}

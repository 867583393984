
import Vue, { defineComponent } from "vue";
import { DataTableHeader } from "vuetify";
import { AxiosError, AxiosResponse } from "axios";
import { addInstitution, deleteInstitution, getAllInstitutions, updateInstitution } from "@/api/InstitutionsService";
import { Institution } from "@/models/Application";
import { isResultOk } from "@/utilities/axios-helpers";
import ConfirmationModal from "@/components/shared/ConfirmationModal.vue";
import InstitutionModal from "./InstitutionModal.vue";
import { debounce } from "underscore";

type InstitutionsTableData = {
    institutions: Institution[];
    loading: boolean;
    institutionToEdit: Institution | null;
    institutionToSave: Institution | null;
    institutionToDelete: Institution | null;
    institutionModal: boolean;
    editConfirm: boolean;
    deleteConfirm: boolean;
};

const headers: DataTableHeader[] = [
    {
        text: "Name",
        value: "name",
        sortable: true,
    },
    {
        text: "Actions",
        value: "id",
        width: "210px",
        sortable: false,
    },
];

export default defineComponent({
    components: {
        InstitutionModal,
        ConfirmationModal,
    },
    data(): InstitutionsTableData {
        return {
            institutions: [],
            loading: true,
            institutionModal: false,
            institutionToEdit: null,
            institutionToSave: null,
            institutionToDelete: null,
            editConfirm: false,
            deleteConfirm: false,
        };
    },
    setup() {
        return {
            headers,
        };
    },
    methods: {
        async getInstitutions() {
            this.loading = true;
            this.institutions = await getAllInstitutions();
            this.loading = false;
        },
        throttledFetchInstitutions: debounce(function () {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            this.getInstitutions();
        }, 250),
        addInstitution() {
            this.institutionToEdit = {} as Institution;
            this.institutionModal = true;
        },
        editInstitution(institution: Institution) {
            this.institutionToEdit = institution;
            this.institutionModal = true;
        },
        deleteInstitution(institution: Institution) {
            this.institutionToDelete = institution;
            this.deleteConfirm = true;
        },
        cancelChange() {
            this.institutionModal = false;
            setTimeout(() => {
                this.institutionToEdit = null;
            }, 500);
        },
        confirmChange(institution: Institution) {
            if (institution.id) {
                this.editConfirm = true;
                this.institutionToSave = institution;

                return;
            }

            this.saveInstitutionDetails(institution);
        },
        async continueSavingInstitutionDetails() {
            if (!this.institutionToSave) {
                return;
            }

            this.editConfirm = false;
            await this.saveInstitutionDetails(this.institutionToSave);
            this.institutionToSave = null;
        },
        async saveInstitutionDetails(institution: Institution) {
            let response: AxiosResponse | AxiosError;
            let message = "Institution added";

            if (institution.id) {
                response = await updateInstitution(institution.id, institution);
                message = "Institution updated";
            } else {
                response = await addInstitution(institution);
            }

            if (isResultOk(response as AxiosResponse)) {
                this.institutionModal = false;

                setTimeout(() => {
                    this.institutionToEdit = null;
                }, 500);

                this.throttledFetchInstitutions();
                Vue.$toast.success(message);

                return;
            }
        },
        confirmDeletion(institution: Institution) {
            this.institutionToDelete = institution;
            this.deleteConfirm = true;
        },
        cancelDeletion() {
            this.deleteConfirm = false;
            this.institutionToDelete = null;
        },
        async performDelete() {
            if (!this.institutionToDelete) {
                return;
            }

            const ok = await deleteInstitution(this.institutionToDelete.id);

            if (ok) {
                this.deleteConfirm = false;
                this.institutionToDelete = null;

                this.throttledFetchInstitutions();

                Vue.$toast.success("Institution successfully deleted");
            }
        },
    },
    created() {
        this.throttledFetchInstitutions();
    },
});


import { defineComponent } from "vue";
import ApplicationSummaryModal from "./ApplicationSummaryModal.vue";

export default defineComponent({
    components: {
        ApplicationSummaryModal,
    },
    props: {
        title: {
            type: String,
            required: true,
        },
        current: {
            default: undefined,
        },
        previous: {
            default: undefined,
        },
        versionDiff: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            applicationSummaryModal: false,
        };
    },
    setup(props) {
        function background() {
            return {
                "grey lighten-4": props.versionDiff,
            };
        }
        return { background };
    },
    methods: {
        viewDifference() {
            this.applicationSummaryModal = true;
        },
        closeModal() {
            this.applicationSummaryModal = false;
        },
    },
});

import { Worklist } from "@/models/ApplicationWorklist";
import { DeveloperProfile } from "@/models/DeveloperProfile";
import { PagedRequest, PagedResponse } from "@/models/PagedResponse";
import { createAxiosInstance, ErrorMessageMap } from "@/utilities/axios-helpers";
import { decode } from "html-entities";

const errorMessageMapAppWorklist: ErrorMessageMap = {
    get: "Something unexpected went wrong retrieving applications to review",
};
const httpWorklist = createAxiosInstance(errorMessageMapAppWorklist);

const errorMessageMapDevWorklist: ErrorMessageMap = {
    get: "Something unexpected went wrong retrieving developer profiles to review",
};
const httpDevWorklist = createAxiosInstance(errorMessageMapDevWorklist);

export async function getAppWorklist(query: PagedRequest): Promise<Worklist> {
    const params = new URLSearchParams({
        page: `${query.page}`,
        itemsPerPage: `${query.itemsPerPage}`,
        sortBy: query.sortBy ?? "",
        sortDesc: `${query.sortDesc}`,
    });

    const response = await httpWorklist.get(`/admin/app-worklist?${params}`).catch((err) => err);
    return decodeWorklist(response.data);
}

function decodeWorklist(worklist: Worklist) {
    worklist.versions?.forEach((item) => {
        item.name = decode(item.name);
        item.developer_name = decode(item.developer_name);
        item.version_string = decode(item.version_string);
    });
    return worklist;
}

export async function getDevelopersForReview(params: PagedRequest): Promise<PagedResponse<DeveloperProfile>> {
    const urlParams = new URLSearchParams(params as unknown as Record<string, string>);
    const response = await httpDevWorklist.get<PagedResponse<DeveloperProfile>>(
        `admin/review/developer?${urlParams.toString()}`
    );
    return decodeDeveloperProfiles(response.data);
}

function decodeDeveloperProfiles(developerProfiles: PagedResponse<DeveloperProfile>) {
    developerProfiles.items?.forEach((profile) => (profile.name = decode(profile.name)));
    return developerProfiles;
}

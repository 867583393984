
import { defineComponent } from "vue";
import { Application, ApplicationFile } from "@/models/Application";
import { getApplication } from "@/api/ApplicationService";
import { Dictionary } from "vue-router/types/router";
import AboutApplicationSection from "@/components/publisher/application-summary/AboutApplicationSection.vue";
import DeploymentRequirementsSection from "@/components/publisher/application-summary/DeploymentRequirementsSection.vue";
import ApplicationFilesSection from "@/components/publisher/application-summary/ApplicationFilesSection.vue";
import ContainersSection from "@/components/publisher/application-summary/ContainersSection.vue";
import ApplicationCardPreviewSection from "@/components/publisher/application-summary/ApplicationCardPreviewSection.vue";
import { getApplicationFiles } from "@/api/ApplicationFilesService";
import ArgoWorkflowSection from "@/components/publisher/application-summary/ArgoWorkflowSection.vue";
import RemoteApplicationSection from "@/components/publisher/application-summary/RemoteApplicationSection.vue";

export default defineComponent({
    metaInfo: {
        title: "Application Summary",
    },
    components: {
        AboutApplicationSection,
        ArgoWorkflowSection,
        DeploymentRequirementsSection,
        ApplicationFilesSection,
        ContainersSection,
        RemoteApplicationSection,
        ApplicationCardPreviewSection,
    },
    data() {
        return {
            application: {} as Application,
            applicationFiles: {} as ApplicationFile,
            loading: false,
        };
    },
    async created() {
        const { application_id, version_id, version_details_id } = this.$route.query as Dictionary<string>;

        this.loading = true;
        this.application = await getApplication(application_id, version_id, version_details_id);
        this.applicationFiles = await getApplicationFiles(application_id, version_id, version_details_id);
        this.$store.commit("setApplication", this.application);
        this.$store.commit("setApplicationFiles", this.applicationFiles);
        this.loading = false;

        this.$store.commit("setCurrentApplicationRequiredFieldsFilled", true);
    },
});


import Vue from "vue";
import Component from "vue-class-component";
import HeaderBar from "@/components/publisher/dashboard/HeaderBar.vue";
import ApplicationTable from "@/components/publisher/dashboard/ApplicationTable.vue";

@Component({
    metaInfo: {
        title: "Home",
    },
    components: {
        HeaderBar,
        ApplicationTable,
    },
})
export default class Dashboard extends Vue {}


import { getPresignedGetUrl } from "@/api/FileUploadService";
import { Developer } from "@/models/Developer";
import { defineComponent, PropType } from "vue";

export default defineComponent({
    props: {
        developer: {
            type: Object as PropType<Developer>,
            required: true,
        },
    },
    data() {
        return {
            imageUrl: undefined as string | undefined,
        };
    },
    async mounted() {
        await this.loadImage();
    },
    methods: {
        async loadImage() {
            if (!this.developer.developer_logo_s3_file_id) {
                this.imageUrl = undefined;

                return;
            }

            this.imageUrl = await getPresignedGetUrl(this.developer.developer_logo_s3_file_id);
        },
    },
});

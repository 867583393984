
import { defineComponent } from "vue";

export default defineComponent({
    props: {
        step: {
            type: Number,
            required: true,
        },
        disabled: {
            type: Boolean,
            required: true,
        },
        handleSaveButton: {
            type: Function,
            required: true,
        },
    },
    computed: {
        stepValue: {
            get() {
                return this.$props.step;
            },
            set(value: number) {
                this.$emit("update:step", value);
            },
        },
        userHasDeveloperRole(): boolean {
            return this.$store.state.roles.includes("developer");
        },
        applicationCannotBeSaved(): boolean {
            return (
                this.$store.state.currentApplication.status === "recalled" ||
                this.$store.state.currentApplication.status === "archived" ||
                this.$store.state.currentApplication.status === "submitted"
            );
        },
    },
});

import { InputType, OutputType } from "@/models/Application";
import { createAxiosInstance, ErrorMessageMap } from "@/utilities/axios-helpers";

const inputErrorMessage: ErrorMessageMap = {
    get: "Something unexpected went wrong retrieving input types",
};

const outputErrorMessage: ErrorMessageMap = {
    get: "Something unexpected went wrong retrieving output types",
};

const inputHttp = createAxiosInstance(inputErrorMessage);
const outputHttp = createAxiosInstance(outputErrorMessage);

export async function getAllInputTypes(): Promise<InputType[]> {
    const response = await inputHttp.get("/input-types");
    return response.status === 200 ? response.data : [];
}

export async function getAllOutputTypes(): Promise<OutputType[]> {
    const response = await outputHttp.get("/output-types");
    return response.status === 200 ? response.data : [];
}


import Vue from "vue";
import MetaInfo from "vue-meta";
import AppHeader from "@/components/AppHeader.vue";
import AppSidebar from "@/components/AppSidebar.vue";

export default Vue.extend({
    name: "App",
    metaInfo(): MetaInfo {
        return {
            titleTemplate: "%s | AIDE HUB",
        };
    },
    components: {
        AppHeader,
        AppSidebar,
    },
});

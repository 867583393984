
import { defineComponent, PropType } from "vue";
import NamedSection from "@/components/publisher/NamedSection.vue";
import CommandStep from "@/components/publisher/container-upload/CommandStep.vue";
import { ContainerCommand } from "@/models/Container";
import { Application } from "@/models/Application";
import { checkContainersUploaded } from "@/api/ApplicationContainersService";
import { Dictionary } from "vue-router/types/router";

export default defineComponent({
    components: {
        NamedSection,
        CommandStep,
    },
    props: {
        commands: {
            required: true,
            type: Object as PropType<ContainerCommand>,
            default: () => ({}),
        },
        containersUploaded: {
            default: false,
            type: Boolean,
        },
        application: {
            required: true,
            type: Object as PropType<Application>,
            default: () => ({}),
        },
    },
    data() {
        return {
            currentTab: 0,
            version_id: "",
            container_names: [] as string[],
            containersFound: false,
            containerUploadUserError: false,
        };
    },
    setup() {
        return {
            containerUploadErrorMessage:
                "Containers have not been uploaded to the container repository. Please rerun the commands above.",
            validationRules: [(value: boolean) => value || "Your confirmation is required"],
        };
    },
    computed: {
        gitBashLoginCommand(): string {
            if (!this.commands.credentials) {
                return "";
            }

            const { authToken, command } = this.commands.credentials;

            return `echo "${authToken}" | docker login --password-stdin ${command}`;
        },
    },
    watch: {
        containersUploaded() {
            this.containersFound = this.containersUploaded;
        },
    },
    emits: ["update:containersUploaded"],
    methods: {
        async checkImageContainersUploaded() {
            const { version_id } = this.$route.query as Dictionary<string>;
            this.version_id = version_id;

            try {
                if (this.application.container_repositories) {
                    this.container_names = this.application.container_repositories?.map((c) => c.name);
                }

                const containersUploaded = await checkContainersUploaded(this.version_id, this.container_names);

                this.containersFound = containersUploaded[0];
                this.$emit("update:containersUploaded", this.containersFound);
                this.containerUploadErrorMessage = containersUploaded[1];
                this.containerUploadUserError = containersUploaded[2];
            } catch {
                this.containersFound = false;
                this.$emit("update:containersUploaded", this.containersFound);
            }
        },
    },
});

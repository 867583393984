
import { defineComponent, PropType } from "vue";
import NamedSection from "../NamedSection.vue";
import { getAllOutputTypes } from "@/api/InputOutputTypeService";
import { IArtifactOutput } from "@/models/Application";
import ArtifactOutput from "./ArtifactOutput.vue";
import { mapToOutputTypeOptions, OutputTypesOption } from "./deployment-requirements-helper";

export default defineComponent({
    components: {
        NamedSection,
        ArtifactOutput,
    },
    props: {
        artifactOutputs: {
            type: Array as PropType<IArtifactOutput[]>,
            default: () => [
                {
                    id: "",
                    name: "",
                    type: "",
                },
            ],
        },
    },
    data() {
        return {
            outputTypesOptions: [] as OutputTypesOption[] | undefined,
            menuProps: { contentClass: "output-type-dropdown" },
            loading: false,
        };
    },
    computed: {
        artifactOutputsValue: {
            get() {
                return this.artifactOutputs;
            },
            set(artifactOutputs: IArtifactOutput[]) {
                this.$emit("update:artifactOutputs", artifactOutputs);
            },
        },
    },
    methods: {
        addArtifact(): void {
            const artifact = {
                name: "",
            } as IArtifactOutput;

            this.artifactOutputsValue.push(artifact);
        },
        deleteArtifact(index: number) {
            const artifacts = [...this.artifactOutputsValue];

            if (artifacts.length === 1) {
                artifacts[0] = {} as IArtifactOutput;
            } else {
                artifacts.splice(index, 1);
            }

            this.artifactOutputsValue = artifacts;
        },
        updateArtifact(artifact: IArtifactOutput, index: number) {
            const updatedArtifactOutputs = [...this.artifactOutputs] ?? [];
            updatedArtifactOutputs[index] = artifact;
            this.artifactOutputsValue = updatedArtifactOutputs;
        },
    },
    async mounted() {
        this.loading = true;
        const options = await getAllOutputTypes();
        this.outputTypesOptions = mapToOutputTypeOptions(options);
        this.loading = false;
    },
});

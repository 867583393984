
import { defineComponent, PropType } from "vue";
import ConfirmationModal from "@/components/shared/ConfirmationModal.vue";
import { createDeveloper, updateDeveloper } from "@/api/DeveloperService";
import { Developer, DeveloperProfileStatus } from "@/models/Developer";
import Vue from "vue";

export default defineComponent({
    components: {
        ConfirmationModal,
    },
    filters: {
        chipStatusBg(status: DeveloperProfileStatus) {
            if (status === DeveloperProfileStatus.LIVE) {
                return "light-green lighten-4";
            } else if (status === DeveloperProfileStatus.SUBMITTED) {
                return "blue lighten-5";
            } else if (status === DeveloperProfileStatus.REJECTED) {
                return "red lighten-5";
            }
        },
        chipStatusTextColour(status: DeveloperProfileStatus) {
            if (status === DeveloperProfileStatus.LIVE) {
                return "light-green darken-4";
            } else if (status === DeveloperProfileStatus.SUBMITTED) {
                return "blue darken-4";
            } else if (status === DeveloperProfileStatus.REJECTED) {
                return "red darken-4";
            }
        },
        chipStatusText(status: DeveloperProfileStatus) {
            if (status === DeveloperProfileStatus.LIVE) {
                return "Live";
            } else if (status === DeveloperProfileStatus.SUBMITTED) {
                return "Submitted - review pending";
            } else if (status === DeveloperProfileStatus.REJECTED) {
                return "Changes rejected";
            }
        },
    },
    props: {
        editing: {
            type: Boolean,
            required: true,
        },
        disabled: {
            type: Boolean,
            required: true,
        },
        developerExists: {
            type: Boolean,
            required: true,
        },
        developer: {
            type: Object as PropType<Developer>,
            required: true,
        },
        loading: {
            type: Boolean,
            required: false,
        },
    },
    computed: {
        editingValue: {
            get() {
                return this.$props.editing;
            },
            set(value: string) {
                this.$emit("update:editing", value);
            },
        },
        showDeveloperProfileStatus(): boolean {
            return !this.editingValue && !this.loading && this.$route.fullPath.includes("developer-profile");
        },
    },
    data() {
        return {
            cancelConfirm: false,
        };
    },
    methods: {
        handleCancelButton() {
            this.cancelConfirm = false;
            this.editingValue = false;
        },
        async handleSaveButton() {
            let responseOk = false;

            if (this.developerExists) {
                responseOk = await updateDeveloper(this.developer.developer_id, this.developer);
            } else {
                responseOk = await createDeveloper(this.developer);
            }

            if (responseOk) {
                this.editingValue = false;

                Vue.$toast.success("Developer profile was saved successfully");

                if (!this.developerExists) {
                    this.$router.push({ path: "/callback" });
                }
            }
        },
    },
});


import { defineComponent } from "vue";
import NamedSection from "../publisher/NamedSection.vue";
import { generateUploadUrl, getPresignedGetUrl, uploadFile } from "@/api/FileUploadService";
import DeveloperProfileSection from "./DeveloperProfileSection.vue";
import ConfirmationModal from "@/components/shared/ConfirmationModal.vue";
import Vue from "vue";

export default defineComponent({
    components: {
        NamedSection,
        DeveloperProfileSection,
        ConfirmationModal,
    },
    props: {
        imageKey: {
            type: String,
            required: false,
        },
        editing: {
            type: Boolean,
            required: true,
        },
        developerExists: {
            type: Boolean,
            required: true,
        },
        imageKeyPrevious: {
            type: String,
            required: false,
        },
    },
    computed: {
        imageKeyValue: {
            get(): string | undefined {
                return this.imageKey;
            },
            set(key: string | undefined) {
                this.$emit("update:imageKey", key);
            },
        },
        validationRules() {
            return [
                (value: File) => !!value || "Required",
                (value: File) => this.acceptedFileTypes.includes(value?.type) || "Unsupported file type",
                (value: File) => value?.size <= 5000000 || "Images should be 5MB or less",
            ];
        },
    },
    data() {
        return {
            selectedFile: null,
            fileInvalid: false,
            fileUploading: false,
            replaceConfirmDialog: false,
            imageUrl: undefined as string | undefined,
            imageUrlPrevious: undefined as string | undefined,
            acceptedFileTypes: ["image/png", "image/jpeg", "image/jpg"],
            errorMessage: "",
        };
    },
    watch: {
        async selectedFile() {
            if (!this.selectedFile) {
                return;
            }

            if (!this.acceptedFileTypes.includes((this.selectedFile as File).type)) {
                this.fileInvalid = true;
                return;
            }

            this.fileInvalid = false;
            await this.developerLogoUploaded(this.selectedFile);
        },
        async imageKeyValue() {
            await this.loadImage();
        },
        async imageKeyPrevious() {
            await this.loadImagePrevious();
        },
        async editing() {
            await this.loadImage();
            await this.loadImagePrevious();
        },
    },
    async mounted() {
        await this.loadImage();
        await this.loadImagePrevious();
    },
    methods: {
        async developerLogoUploaded(file: File) {
            try {
                const [{ url, key }, error] = await generateUploadUrl(file.name);
                this.errorMessage = error;

                this.fileUploading = true;
                await uploadFile(url, file);

                this.$emit("update:imageKey", key);
                this.fileUploading = false;
            } catch {
                Vue.$toast.error("Something went wrong uploading the developer logo, please retry.");
                this.$emit("update:imageKey", "");
                this.selectedFile = null;
                this.fileUploading = false;
            }
        },
        async replaceUploadedImage() {
            if (this.developerExists) {
                await this.deleteUploadedImage();
            } else {
                this.imageKeyValue = undefined;
                this.selectedFile = null;
            }

            this.replaceConfirmDialog = false;
        },
        async deleteUploadedImage() {
            if (!this.imageKey) {
                return;
            }

            this.$emit("update:imageKey", "");
            this.selectedFile = null;
        },
        async loadImage() {
            if (!this.imageKeyValue) {
                this.imageUrl = undefined;

                return;
            }

            this.imageUrl = await getPresignedGetUrl(this.imageKeyValue);
        },
        async loadImagePrevious() {
            if (!this.imageKeyPrevious) {
                this.imageUrlPrevious = undefined;

                return;
            }

            this.imageUrlPrevious = await getPresignedGetUrl(this.imageKeyPrevious);
        },
    },
});
